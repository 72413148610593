import React from "react";
import styles from "@/page-components/idle-resource/idle.module.css";
import IdleResourceActivityGraph from "./idle-resource-activity-graph.part";
import DiscardedTable from "./discarded-table.part";
import FilterSection from "../aws-filter-section/filter-section.part";
import IdleHistoryHeader from "./idle-history-header.part";
import clsx from "clsx";

const IdleResourceHistoryPage = ({
  service,
  name,
  ipName,
  vmName,
  diskName,
}) => {
  return (
    <div className={styles.header_page}>
      <IdleHistoryHeader showDropdown={true} service={service} vmName={vmName} diskName={diskName} ipName={ipName} />
      <div className={styles.history_filter}>
        <div
          className={clsx(styles.adding_border_line)}
          style={{ width: "82%" }}
        >
          <div className={styles.idle_history_page} style={{ width: "100%" }}>
            <IdleResourceActivityGraph service={service} />
            <DiscardedTable ipName={ipName} service={service} name={name} vmName={vmName} diskName={diskName} />
          </div>
        </div>
        <div
          style={{ height: "100%", width: "18%", padding: "0px 32px 0px 0px" }}
        >
          <FilterSection service={service} name={name} />
        </div>
      </div>
    </div>
  );
};

export default IdleResourceHistoryPage;
