import React from "react";
import {
  Line,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ComposedChart,
  ResponsiveContainer,
} from "recharts";
import styles from "@/page-components/idle-resource/idle.module.css";
import tooltipStyles from "@/page-components/snapshot/snapshots.module.css";
import CurrencyFormatter from "@/lib/value-converison";

const SnapHistoryBArChart = ({ data }) => {
  return (
      <ResponsiveContainer width="100%" height={300}>
        <BarChart
          data={data}
          className={styles.legend_custom_color}
          margin={{ top: 0, right: 3, left: -13, bottom: 0 }}
          stackOffset="sign"
        >
          <XAxis
            dataKey="month"
            tickLine={false}
            axisLine={false}
            fontSize={10}
            fontWeight={500}
            stroke="var(--Neutral-D3, #495057)"
          />
          <YAxis
            label={{ fill: "#A3A3A3" }}
            tickLine={false}
            axisLine={false}
            fontSize={10}
            fontWeight={400}
            stroke="var(--Neutral-D3, #495057)"
          />
          <CartesianGrid strokeDasharray="0" stroke="#F0F0F0" />
          <Tooltip
            content={<CustomTooltip />}
            cursor={{ fill: "transparent" }}
          />
          <Legend />
          <Bar dataKey="deleted" barSize={80} fill="#056EE5" name="Snapshots Deleted" />
          <Bar dataKey="added"  barSize={80}  fill="#E8BF67" name="Snapshots Added" />
        </BarChart>
      </ResponsiveContainer>
  );
};

export default SnapHistoryBArChart;

const CustomTooltip = ({ active, payload, label }) => {


  if (active && payload && payload.length) {
    const deletedEntry = payload.find((entry) => entry.dataKey === "deleted");
    const addedEntry = payload.find((entry) => entry.dataKey === "added");

    const savingValue = payload[0]?.payload?.deleted_savings|| 0;

    const deletedValue = deletedEntry?.value || 0;
    const addedValue = addedEntry?.value || 0;
    const deletedColor = deletedEntry?.color || "#056EE5";
    const addedColor = addedEntry?.color || "#E8BF67";

    return (
      <div className={tooltipStyles.custom_tooltip} style={{ width: "275px",background:"#202020",borderRadius:"8px" }}>
        <div
          className={tooltipStyles.tooltip_date}
          style={{ paddingLeft: "8px" }}
        >
          <div className={tooltipStyles.tooltip_date_css}>{label}</div>
        </div>

        <table style={{ width: "92%", margin: "0px 12px 4px 12px" }}>
          <tbody style={{ width: "100%" }}>
            <tr className={tooltipStyles.tooltip_row}>
              <div className={tooltipStyles.tooltip_table_flex}>
                <div className={tooltipStyles.display_icon}>
                  <div
                    style={{
                      width: "12px",
                      height: "12px",
                      backgroundColor: deletedColor,
                      borderRadius: "2px",
                    }}
                  ></div>
                  <span className={tooltipStyles.tooltip_cloud_name}>
                  Snapshots Deleted
                  </span>
                </div>
                <span className={tooltipStyles.tooltip_tr_value}>
                  {deletedValue}
                  {/* <CurrencyFormatter hideDollar={true} value={deletedValue} /> */}
                </span>
              </div>
            </tr>
            <tr className={tooltipStyles.tooltip_row}>
              <div className={tooltipStyles.tooltip_table_flex}>
                <div className={tooltipStyles.display_icon}>
                  <div
                    style={{
                      width: "12px",
                      height: "12px",
                      backgroundColor: addedColor,
                      borderRadius: "2px",
                    }}
                  ></div>
                  <span className={tooltipStyles.tooltip_cloud_name}>
                  Snapshots Added
                  </span>
                </div>
                <span className={tooltipStyles.tooltip_tr_value}>
                  {addedValue}
                  {/* <CurrencyFormatter hideDollar={true} value={addedValue} /> */}
                </span>
              </div>
            </tr>
            <tr className={tooltipStyles.tooltip_row}>
              <div className={tooltipStyles.tooltip_table_flex}>
                <span
                  className={tooltipStyles.tooltip_cloud_name}
                  style={{ fontSize: "12px",display:"flex",flexDirection:"column" }}
                >
                  Savings
                  <span style={{color:"#797B80",fontSize:"12px",fontWeight:"500"}}>(Deleted Snapshots)</span>
                </span>
                <span className={tooltipStyles.tooltip_tr_value}>
                  <CurrencyFormatter value={savingValue}  />
                </span>
              </div>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
  return null;
};
