import React, { useEffect, useMemo } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import styles from "./savings.module.css";

const TabsSaving = ({ ShowTabs, onTabChange }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const type = searchParams.get("savings");
    if (type) {
      onTabChange(type);
    }
  }, [searchParams, onTabChange]);

  const activeTab = useMemo(() => {
    return searchParams.get("savings") || "Idle Resources";
  }, [searchParams]);

  const tableChange = (e) => {
    setSearchParams({
      savings: e,
    });
    onTabChange(e);
  };

  return (
    <div className="btn__Container">
      <div className="btn-combo__Container">
        {ShowTabs && (
          <Tabs
            activeKey={activeTab}
            className={styles.mytabs_button}
            // id="fill-tab-example"
            fill
            onSelect={(e) => {
              tableChange(e);
              
            }}
            style={{ gap: "4px" }}
          >
            <Tab eventKey="Idle Resources" title="Idle Resources"></Tab>
            {/* <Tab eventKey="Rightsizing" title="Rightsizing"></Tab> */}
            <Tab eventKey="Snapshots" title="Snapshots"></Tab>
          </Tabs>
        )}
      </div>
      <div className="text-btn__Container">Updated Today at 7:05AM</div>
    </div>
  );
};

export default TabsSaving;
