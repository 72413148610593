import { AppContext } from "@/App.context";
import config from "@/config";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";

// success: true,
// message: 'Fetched Successfully.'
// data: {
//     ticket_title: '',
//     ticket_id: '',
//     ticket_status: '',
//     comment: '',
//     resources : {
//         ...
//     }
// }
// }

/**
 * @typedef GetAllStatistics
 * @property {GetTotalStatistics} data
 */
/**
 * @typedef GetTotalStatistics
 * @property {number} ticket_title
 * @property {number} ticket_id
 * @property {string} ticket_status
 * @property {string} comment
 * @property {GetResource} resources
 */

/**
 * @typedef GetResource
 * @property {GetDiskStatistics=} account_name
 * @property {GetVmStatistics=} name
 * @property {GetIpStatistics=} resource_type
 * @property {GetLoadStatistics=} savings
 */

/**
 * @typedef GetAllStatisticsRes
 * @property {GetAllStatistics[]} statistics
 */
/**
 * @typedef GetStatisticsReq
 * @property {'aws' | 'gcp' | 'azure' | 'all-cloud' } service
 */

export const getIdleTicketStatus = (token) => {
  /**
   * @param {GetStatisticsReq} req
   * @param {AbortSignal=} signal
   * @returns {Promise<GetAllStatistics>}
   */
  return async ({ service, resource_id }, signal) => {
    const url = new URL(
      `${config.apiHost}/v1/${service}/idle-resources/ticket-status/${resource_id}`
    );

    const owners = await fetch(url.toString(), {
      method: "GET",
      signal,
      headers: {
        Authorization: token,
      },
    });
    if (!owners.ok) {
      throw new Error((await owners.json()).message);
    }
    return await owners.json();
  };
};

/**
 * @param {GetStatisticsReq} req
 * @param {any[]=} deps
 */
export const useIdleTicketStatus = (req, deps = []) => {
  const appContext = useContext(AppContext);
  const query = useQuery({
    queryKey: ["getIdleTicketStatus", appContext.idToken, req, ...deps],
    queryFn: ({ signal }) =>
      getIdleTicketStatus(appContext.idToken)(req, signal),
  });
  return query;
};
