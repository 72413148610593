import React from "react";
import styles from "@/page-components/snapshot/snapshots.module.css";
import style from "@/components/state-change/state.module.css";
import clsx from "clsx";
import DoneIcon from "@/assets/img/doneStateChange.svg";

const ViewModalTable = ({ selectedRows,description, jiraStatus ,selectedResourceId }) => {
  return (
    <div className={styles.snap_table_section} style={{display:"flex",flexDirection:"column",gap:"24px",marginTop:"4px"}}>
            <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          gap: "4px",
        }}
      >
        <span className={style.selected_resource_des}>Description</span>
        <span className={style.description_css}>{description || "--"}</span>
      </div>
      <div
        className={clsx(styles.tableContainer, styles.table_container)}
        style={{ width: "100%", overflowY: "scroll", maxHeight: "350px" }}
      >
        <table className="table " style={{ margin: "0px", width: "100%" }}>
          <thead className={styles.stickyHeader}>
            <tr
              style={{
                background: "#F6F6F6",
                borderBottom: "1px solid #F6F6F6",
              }}
            >
              <th
                className={clsx(styles.snap_th_class, styles.textAlignStart)}
                style={{ textAlign: "start" }}
              >
                Account Name
              </th>
              <th
                className={clsx(styles.snap_th_class, styles.textAlignStart)}
                style={{ textAlign: "start" }}
              >
                Snapshot Name
              </th>
              <th
                className={clsx(styles.snap_th_class, styles.textAlignend)}
                style={{ textAlign: "end" }}
              >
                Projected Savings
              </th>
              <th className={clsx(styles.snap_th_class, styles.table_td_width)}>
                Status
              </th>
              <th className={clsx(styles.snap_th_class, styles.table_td_width)}>
                Jira Status
              </th>
            </tr>
          </thead>
          <tbody
            className={styles.scrollableBody}
            style={{
              borderRight: "white",
              borderLeft: "white",
            }}
          >
            {(selectedRows || []).map((item, index) => (
              <tr key={index}
              className={clsx(style.table_row, "p-0", {
                [style.highlightedRow]: item?.snapshot_id === selectedResourceId,
              })}>
                <td className={clsx(styles.snap_table_data__Class, "elipese")}>
                  {item?.account_name !== null ? item?.account_name : "--"}
                </td>
                <td className={clsx(styles.snap_table_data__Class, "elipese")}>
                  {item?.snapshot_name !== null ? item?.snapshot_name : "--"}
                </td>
                <td
                  className={clsx(
                    styles.snap_table_data__Class,
                    "elipese",
                    styles?.textAlignend
                  )}
                  style={{color:"#127E24",fontWeight:"600",fontSize:"13px"}}
                >
                  {item?.snapshot_cost !== null
                    ? "$" + Number(item?.snapshot_cost || 0).toFixed(2)
                    : "--"}
                </td>
                <td className={styles.snap_table_data__Class}>
                  {item?.status == "in_progress" && (
                    <span className={styles.badgeNameProgress}>
                      In Progress
                    </span>
                  )}
                  {item?.status == ("deleted" || "Retained_deleted") && (
                    <span className={styles.badgeNameDone}>
                      {" "}
                      <img src={DoneIcon} alt=""></img> Deleted
                    </span>
                  )}
                </td>
                <td className={styles.snap_table_data__Class}>
                  <span
                    style={{
                      padding: "2px 8px 4px 8px",
                      background: "#F9F9F9",
                      color: "#797B80",
                    }}
                  >
                    {jiraStatus || "--"}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ViewModalTable;
