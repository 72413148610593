import { AppContext } from "@/App.context";
import config from "@/config";
import { useMutation } from "@tanstack/react-query";
import { useContext } from "react";
import Joi from "joi";

// PUT .... /update-status
 
// {
// 	comment: '',
// 	status: 'accept/retained',
// 	resources: [
// 		{
// 			accountId: dslkf,
// 			....
// 		}
// 	]
// }

/** @type {Joi<UpdateAcceptRecommendationsReq>} */
export const IdleResourceChangeReq = Joi.object({
  resourceId: Joi.string().required(),
  service: Joi.string(),
  resource_saving_id: Joi.string().allow(null),
  status: Joi.string()
    .valid(
      "retained",
      "accept",
    )
    .required(),
  comment: Joi.string().required().messages({
    "any.required": "*Comment cannot be empty",
    'string.empty' : "*Comment cannot be empty",
  }),
});



/**
 * @typedef UpdateAcceptRecommendationsReq
 * @property {'aws' | 'azure'} service
 * @property { "retained" | "accept" } status
 * @property {string=} comment
 * @property {AcceptData=} acceptData
 */

export const IdleResourceUpdateStatus = (token) => {
  /**
   *
   * @param {UpdateAcceptRecommendationsReq} req
   * @returns {Promise<boolean>}
   */
  return async (req) => {
    const { resource_saving_id,service, ...rest } = req;
    const addFields = await fetch(
      `${config.apiHost}/v1/${service}/idle-resources/update-status`,
      {
        method: "PUT",
        body: JSON.stringify(rest),
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    if (!addFields.ok) {
      throw new Error((await addFields.json()).message);
    }
    return await addFields.json();
  };
};

/**
 * @param {any[]=} deps
 * @param {UpdateAcceptRecommendationsReq} req
 */
export const useIdleUpdateStatus = (deps = []) => {
  const appContext = useContext(AppContext);
  const mutation = useMutation({
    mutationKey: ["IdleResourceUpdateStatus", appContext.idToken, ...deps],
    mutationFn: IdleResourceUpdateStatus(appContext.idToken),
  });
  return mutation;
};
