import { AppContext } from "@/App.context";
import config from "@/config";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import {
  API_LOADING_ERROR,
  API_NO_DATA_FOUND_FOR_FILTERS_ERROR,
  API_NOT_FOUND_ERROR,
  ERROR_STATUS_CODES,
  API_NO_DATA_FOUND_ERROR,
  API_NO_DATA_FOUND_FOR_QUERY_RESOURCE_FILTERS,
  API_NO_DATA_FOUND_FOR_QUERY_IDLE_FILTERS,
} from "@/lib/errors/error-constants";

/**
 * @typedef GetAllStatistics
 * @property {GetDataStatistics} data
 * @property {GetTotalCountStatistics} count
 */

/**
 * @typedef GetTotalCountStatistics
 * @property {number} total_data
 */

/**
 * @typedef GetDataStatistics
 * @property {string} account_name
 * @property {string} name
 * @property {string} id
 * @property {string} resource_group_name
 * @property {string} resource_type
 * @property {string} region
 * @property {string} owner
 * @property {number} idle_days
 * @property {string} resource_id
 * @property {number} saving
 * @property {string} status
 */

/**
 * @typedef GetIdleFilters
 * @property {string[]} accounts
 * @property {string[]} rg
 * @property {string[]} regions
 * @property {string[]} tags
 */

/**
 * @typedef GetAllStatisticsRes
 * @property {GetAllStatistics[]} statistics
 */
/**
 * @typedef GetStatisticsReq
 * @property {'aws' | 'gcp' | 'azure'  } service
 * @property {'ip' | 'disk' | 'vm' | 'lb' |'all'} resourceType
 * @property {GetIdleFilters} filters
 * @property {number} page
 * @property {number} recordsPerPage
 * @property {number} idleDays
 * @property {'true' | 'false'} desc
 * @property {'account_name' | 'name' | 'resource_group_name' |'resource_id' | 'resource_type' | 'region' | 'idle_days' | 'saving'} orderBy
 * @property { 'false' | 'true' } untagged
 */

// ------------------------------------------api for the archive table------------------------------------------------------------

export const getArchieveStatistics = (token) => {
  /**
   * @param {GetStatisticsReq} req
   * @returns {Promise<GetAllStatistics>}
   */
  return async (
    {
      service,
      page,
      resourceType,
      recordsPerPage,
      filters,
      desc,
      orderBy,
      status,
      idleDays,
    },
    signal
  ) => {
    const body = {
      accounts: filters?.accounts || [],
      regions: filters?.regions || [],
      tags: filters?.tags || [],
    };

    if (service === "azure") {
      body.rg = filters?.rg || [];
    }
    const owners = await fetch(
      `${config.apiHost}/v1/${service}/idle-resources/saving-details?page=${page}&recordsPerPage=${recordsPerPage}&resourceType=${resourceType}&idleDays=${idleDays}&desc=${desc}&orderBy=${orderBy}&status=${status}`,
      {
        method: "POST",
        body: JSON.stringify(body),
        signal,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    if (!owners.ok) {
      if (ERROR_STATUS_CODES.includes(owners.status)) {
        throw new Error(API_LOADING_ERROR);
      }
      if (owners.status === 404) {
        throw new Error(API_NOT_FOUND_ERROR);
      }
      throw new Error((await owners.json()).message);
    }

    const data = await owners.json();

    if (data?.data.length === 0) {
      if (resourceType && resourceType !== "all") {
        throw new Error(API_NO_DATA_FOUND_FOR_QUERY_RESOURCE_FILTERS);
      }
      throw new Error(API_NO_DATA_FOUND_ERROR);
    }
    return data;
  };
};

/**

 * @param {GetStatisticsReq} req
 * @param {any[]=} deps
 */
export const useArchieveStatistics = (req, deps = []) => {
  const appContext = useContext(AppContext);
  const query = useQuery({
    queryKey: ["getArchieveStatistics", req, ...deps],
    queryFn: ({ signal }) =>
      getArchieveStatistics(appContext.idToken)(req, signal),
  });
  return query;
};

// ------------------------------api for the saving details table-------------------------------------------------------------
export const getSavingTableStatistics = (token) => {
  return async (
    {
      service,
      page,
      resourceType,
      recordsPerPage,
      idleDays,
      desc,
      orderBy,
      filters,
      untagged,
      status,
    },
    signal
  ) => {
    const body = {
      accounts: filters?.accounts || [],
      regions: filters?.regions || [],
      tags: filters?.tags || [],
    };

    if (service === "azure") {
      body.rg = filters?.rg || [];
    }

    const url = new URL(
      `${config.apiHost}/v1/${service}/idle-resources/saving-details?page=${page}&recordsPerPage=${recordsPerPage}&resourceType=${resourceType}&idleDays=${idleDays}&desc=${desc}&orderBy=${orderBy}&status=${status}`
    );

    if (untagged) {
      url.searchParams.append("untagged", "true");
    }

    const owners = await fetch(url.toString(), {
      method: "POST",
      body: JSON.stringify(body),
      signal,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    if (!owners.ok) {
      if (ERROR_STATUS_CODES.includes(owners.status)) {
        throw new Error(API_LOADING_ERROR);
      }
      if (owners.status === 404) {
        throw new Error(API_NOT_FOUND_ERROR);
      }
      throw new Error((await owners.json()).message);
    }

    const data = await owners.json();

    if (data?.data?.length === 0) {
      if (untagged) {
        throw new Error(API_NO_DATA_FOUND_FOR_FILTERS_ERROR);
      }
      if (
        resourceType &&
        resourceType !== "all" &&
        idleDays &&
        idleDays !== "all"
      ) {
        throw new Error(API_NO_DATA_FOUND_FOR_FILTERS_ERROR);
      }
      if (resourceType && resourceType !== "all") {
        throw new Error(API_NO_DATA_FOUND_FOR_QUERY_RESOURCE_FILTERS);
      }
      if (idleDays && idleDays !== "all") {
        throw new Error(API_NO_DATA_FOUND_FOR_QUERY_IDLE_FILTERS);
      }
      if (
        body?.accounts?.length ||
        body?.regions?.length ||
        body?.tags?.length ||
        body?.rg?.length
      ) {
        throw new Error(API_NO_DATA_FOUND_FOR_FILTERS_ERROR);
      } else {
        throw new Error(API_NO_DATA_FOUND_ERROR);
      }
    }
    return data;
  };
};

/**

 * @param {GetStatisticsReq} req
 * @param {any[]=} deps
 */
export const useSavingTableStatistics = (req, deps = []) => {
  const appContext = useContext(AppContext);
  const query = useQuery({
    queryKey: ["getSavingTableStatistics", req, ...deps],
    queryFn: ({ signal }) =>
      getSavingTableStatistics(appContext.idToken)(req, signal),
  });
  return query;
};
// --------------------------------------api for the discarded table-----------------------------------------------------------

export const getDiscardedTableStatistics = (token) => {
  return async (
    {
      service,
      page,
      resourceType,
      recordsPerPage,
      idleDays,
      desc,
      orderBy,
      filters,
      untagged,
      period,
      start_date,
      end_date,
    },
    signal
  ) => {
    const body = {
      accounts: filters?.accounts || [],
      regions: filters?.regions || [],
      tags: filters?.tags || [],
      period: period,
      start_date: start_date,
      end_date: end_date,
    };

    // if (service === "azure") {
    //   body.rg = filters?.rg || [];
    // }

    const url = new URL(
      `${config.apiHost}/v1/${service}/idle-resources/history-details?page=${page}&recordsPerPage=${recordsPerPage}&resourceType=${resourceType}&idleDays=${idleDays}&desc=${desc}&orderBy=${orderBy}`
    );

    const owners = await fetch(url.toString(), {
      method: "POST",
      body: JSON.stringify(body),
      signal,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    if (!owners.ok) {
      if (ERROR_STATUS_CODES.includes(owners.status)) {
        throw new Error(API_LOADING_ERROR);
      }
      if (owners.status === 404) {
        throw new Error(API_NOT_FOUND_ERROR);
      }
      throw new Error((await owners.json()).message);
    }

    const data = await owners.json();

    if (data?.data?.data?.data?.length === 0) {
      if (
        resourceType &&
        resourceType !== "all" &&
        idleDays &&
        idleDays !== "all"
      ) {
        throw new Error(API_NO_DATA_FOUND_FOR_FILTERS_ERROR);
      }
      if (resourceType && resourceType !== "all") {
        throw new Error(API_NO_DATA_FOUND_FOR_QUERY_RESOURCE_FILTERS);
      }
      if (idleDays && idleDays !== "all") {
        throw new Error(API_NO_DATA_FOUND_FOR_QUERY_IDLE_FILTERS);
      }
      if (body.accounts.length || body.regions.length) {
        throw new Error(API_NO_DATA_FOUND_FOR_FILTERS_ERROR);
      } else {
        throw new Error(API_NO_DATA_FOUND_ERROR);
      }
    }
    return data;
  };
};

/**

 * @param {GetStatisticsReq} req
 * @param {any[]=} deps
 */
export const useDiscardedTableStatistics = (req, deps = []) => {
  const appContext = useContext(AppContext);
  const query = useQuery({
    queryKey: ["getDiscardedTableStatistics", req, ...deps],
    queryFn: ({ signal }) =>
      getDiscardedTableStatistics(appContext.idToken)(req, signal),
  });
  return query;
};
