import React, { useContext, useMemo, useState } from "react";
import DiscardedTable from "../discardedTable";
import { useSearchParams } from "react-router-dom";
import copy from "@/assets/img/copy-06 1.svg";
import FilterSection from "../filter_section/filterSection";
import HistoryChartSection from "./historyChartSection.part";
import { SnapshotContext } from "../../snap.contex";
import { format } from "date-fns";
import { useHistoryChartStats } from "@/services/snapshots/getHistoryChartStats.repo";
import { useHistoryTableStats } from "@/services/snapshots/getHistoryTableStats.repo";

function HistrotSection({ service, name }) {
  const context = useContext(SnapshotContext);
  const [searchParams, setSearchParams] = useSearchParams();

  const [showCopyMessage, setShowCopyMessage] = useState(false);
  const page = searchParams.get("page");
  const itemsPerPage = searchParams.get("itemsPerPage");
  const parsedPage = !Number.isNaN(parseInt(page)) ? parseInt(page) : 1;
  // const [Orderby, setOrderby] = useState(second)
  const parsedItemsPerPage = !Number.isNaN(parseInt(itemsPerPage))
    ? parseInt(itemsPerPage)
    : 10;

  const Historyquery = useMemo(() => {
    const accounts = context?.selectedAccounts?.length
      ? context.selectedAccounts.map((e) => e.id)
      : undefined;

    const regions = context?.selectedRegions?.length
      ? context.selectedRegions.map((e) => e.id)
      : undefined;

    const diskIds = context?.selectedDisk?.length
      ? context.selectedDisk.map((e) => e.id)
      : undefined;

    const resourceGroupNames = context?.selectedResourceGroup?.length
      ? context?.selectedResourceGroup.map((e) => e.id)
      : undefined;
    return {
      recordsPerPage: parsedItemsPerPage || 10,
      orderBy: "snapshot_id",
      desc: searchParams.get("desc") || "true",
      page: parsedPage || 1,
      accounts,
      regions,
      diskIds,
      service,
      resourceGroupNames,
      toDate: context?.toDate,
      fromDate: context?.fromDate,
      period: context?.selectMonthRange.toLowerCase(),
    };
  }, [
    context.selectedAccounts,
    context.selectedRegions,
    context.selectedDisk,
    context?.selectedResourceGroup,
    context?.toDate,
    context?.fromDate,
    context?.selectMonthRange,
    parsedItemsPerPage,
    searchParams,
    parsedPage,
    service,
  ]);

  const {
    data: historChartData,
    isError: historChartError,
    error: historChartListError,
    isLoading: historChartLoading,
    refetch: historChartRefetch,
  } = useHistoryChartStats(Historyquery);

  const {
    data: historTableData,
    isError: historTableError,
    error: historTableListError,
    isLoading: historTableLoading,
    refetch: historTableRefetch,
  } = useHistoryTableStats(Historyquery);

  return (
    <div style={{ display: "flex", paddingRight: "40px" }}>
      <div style={{ width: "82%" }}>
        <HistoryChartSection
          service={service}
          data={historChartData}
          historChartListError={historChartListError}
          historChartLoading={historChartLoading}
        />
        <DiscardedTable
          showCopyMessage={showCopyMessage}
          historTableError={historTableError}
          historTableListError={historTableListError}
          historTableLoading={historTableLoading}
          discardedData={historTableData?.data?.data}
          service={service}
          name={name}
          copy={copy}
        />
      </div>
      <FilterSection service={service} name={name} type="history" />
    </div>
  );
}

export default HistrotSection;
