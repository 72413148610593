import React, { useContext, useEffect } from "react";
import FilterSection from "@/components/kubernates/components/Kubernetes_Filter_Section/Kuber_Filter_Section";
import {
  FilterSearch,
  FilterSearchToggle,
} from "@/components/filter-search/filter-search";
import { Tooltip, Button } from "react-bootstrap";
import styles from "@/page-components/cost-explorer/components/filter-section/filter-section.module.css";
import TagItem from "../../filter-section/tag-item.part";
import { FilterContext } from "../../filter-section/filter-section.component";
import { useDimensionTagsKeys } from "@/services/costExplorer/getDimensionTags.repo";
import add from "@/assets/img/add.svg";

export default function FilterSectionUsage({ filterData, setRange }) {
  const filterContext = useContext(FilterContext);

  const { data: dimensionKeys } = useDimensionTagsKeys({
    service: filterContext.selectedCloud,
  });

  return (
    <div
      className={styles.filter_main_div}
      style={{ padding: "16px 22px 16px 16px", width: "100%" }}
    >
      <div style={{ width: "100%", gap: 4 }}>
        <span className={styles.dimension_name_css}>
          {filterContext?.selectUsageDimension}
        </span>
        <FilterSearch
          options={(filterData?.services || []).map((e) => ({
            id: e.serviceId || null,
            name: e.serviceName || null,
          }))}
          displayValue="name"
          onSelect={(v) => {
            filterContext?.setselectedservice(v.id);
          }}
          selectedValues={[filterContext.selectedservice || ""]}
          toggle={<FilterSearchToggle placeholder="Service" />}
        />
      </div>

      {filterContext?.selectedDimension !== "account_name" &&
        filterContext?.selectedDimension !== "subscriptionName" && (
          <div style={{ width: "100%" }}>
            <span className={styles.dimension_name_css}>
              {filterContext?.selectedCloud === "azure"
                ? "Subscription"
                : "Account"}
            </span>

            <FilterSearch
              multiselect
              options={(filterData?.accounts || []).map((e) => ({
                id: e.accountId || null,
                account: e.accountName || null,
              }))}
              itemRenderer={(item) => {
                return <span>{item.account}</span>;
              }}
              displayValue="account"
              onSelect={(v) => {
                filterContext?.setselectedaccount(v);
              }}
              onRemove={(v) => {
                filterContext?.setselectedaccount(v);
              }}
              selectedValues={filterContext.selectedaccount || []}
              toggle={
                <FilterSearchToggle
                  placeholder={
                    filterContext?.selectedCloud === "azure"
                      ? "Subscription"
                      : "Account"
                  }
                />
              }
            />
          </div>
        )}
      {filterContext?.selectedDimension !== "region" && (
        <div style={{ width: "100%" }}>
          <span className={styles.dimension_name_css}>Region</span>
          <FilterSearch
            multiselect
            options={(filterData?.regions || []).map((e) => ({
              id: e.regionId || null,
              name: e.regionName || null,
            }))}
            displayValue="name"
            onSelect={(v) => {
              filterContext?.setselectedregion(v);
            }}
            onRemove={(v) => {
              filterContext?.setselectedregion(v);
            }}
            selectedValues={filterContext.selectedregion || []}
            toggle={<FilterSearchToggle placeholder="Region" />}
          />
        </div>
      )}

      <hr class="w-100 mb-0 mt-0" />
      <div className={styles.filter_box_div}>
        <div className={styles.filter_name_padding}>
          <div className={styles.filter_name}>Tag</div>
          <Tooltip
            tooltipContent="Please select tags"
            overlayProps={{
              placement: "bottom-end",
            }}
          >
            {(_show, setShow) => {
              const disableBtn =
                filterContext.selectedFilters.length >= 1 &&
                filterContext.selectedFilters.some(
                  (e) => e.dimension.length === 0
                );
              return (
                <div
                  onMouseEnter={() => {
                    if (disableBtn) setShow(true);
                  }}
                  onMouseLeave={() => setShow(false)}
                >
                  <Button
                    disabled={disableBtn}
                    variant="link"
                    className="p-0"
                    onClick={() => {
                      filterContext.setSelectedTags((arr) => {
                        const newArr = [...arr];
                        newArr.push({
                          filterOn: "",
                          filterType: "include",
                          values: [],
                        });
                        return newArr;
                      });
                    }}
                  >
                    <img src={add} alt="" />
                  </Button>
                </div>
              );
            }}
          </Tooltip>
        </div>
        <div className={styles.checkboxArea}>
          <input
            type="checkbox"
            style={{ width: "16px" }}
            checked={filterContext.untagged}
            onChange={(e) => {
              filterContext.setUntagged(e.currentTarget.checked);
            }}
          />
          <span>Show Untagged</span>
        </div>
        {/* <div className={styles.checkboxArea}>
          <input
            type="checkbox"
            style={{ width: "16px" }}
            checked={filterContext.untaggable}
            onChange={(e) => {
              filterContext.setShowUntaggable(e.currentTarget.checked);
            }}
          />
          <span>Show Untaggable Only</span>
        </div> */}
        {filterContext.selectedTags.map((tag, index) => (
          <TagItem
            service={filterContext.selectedCloud}
            tag={tag}
            index={index}
            tagItems={dimensionKeys || []}
          />
        ))}
      </div>
    </div>
  );
}
