import clsx from "clsx";
import React, { useContext, useMemo, useState, useEffect } from "react";
import styles from "@/page-components/search-module/search.module.css";
import { SearchContext } from "../search-context";
import { useSearchStatistics } from "@/services/costExplorer/getSearchHeader.repo";
import HeaderState from "@/components/States/HeaderState";
import {
  formatCount,
  ToolTipComponent,
} from "@/page-components/cost-explorer/components/cost-explorer-header.component";

const SearchHeader = ({ service }) => {
  const context = useContext(SearchContext);

  const query = useMemo(() => {
    return {
      service: service,
      accounts: context?.selectAccounts?.length
        ? context.selectAccounts.map((e) => e.id)
        : undefined,
      regions: context?.selectRegions?.length
        ? context.selectRegions.map((e) => e.id)
        : undefined,
      name: context?.name,
      type: context?.type,
      spendingBy: context.filterMapping[context?.filteredData] || "account",
      fromDate: context?.fromDate,
      toDate: context?.toDate,
      serviceName:
        context?.type === "resource" ? context?.serviceName : undefined,
    };
  }, [
    context.filterMapping,
    context?.filteredData,
    context?.fromDate,
    context?.name,
    context.selectAccounts,
    context.selectRegions,
    context?.serviceName,
    context?.toDate,
    context?.type,
    service,
  ]);

  const { data, isLoading, error } = useSearchStatistics(query);

  const tags = [
    {
      label: service === "azure" ? "Subscription" : "Account",
      value: data?.resource?.account,
    },
    { label: "Resource Type", value: data?.resource?.resourceType },
    { label: "Region", value: data?.resource?.region },
    { label: "Owner", value: data?.resource?.owner },
  ];
  let headerTitle;
  if (data?.type === "serviceCategory") {
    headerTitle = "Service Category";
  } else if (data?.type === "service") {
    headerTitle = "Service";
  } else if (data?.type === "resource") {
    headerTitle = "Resource Name";
  }
  const formattedTotalCost = formatCount(data?.totalCost.toFixed(2) || 0);
  const formattedAvgCost = formatCount(data?.averageDailyCost.toFixed(2) || 0);
  const formattedPotentialCost = formatCount(
    data?.potentialSavings.toFixed(2) || 0
  );

  useEffect(() => {
    if (data?.title) {
      context?.setTitle(data?.title);
    }
  }, [context, data?.title]);

  return (
    <>
      {isLoading && <HeaderState />}
      {(data || error) && (
        <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
          <div className={styles.main_div}>
            {/* ------------first part---------------- */}
            <div className={clsx(styles.first_data, styles.search_first_part)}>
              <span className={styles.category_name}>
                {headerTitle || "--"}
              </span>
              <span
                className={clsx(styles.service_name, styles.trucate_css_in)}
                title={data?.title || "--"}
              >
                {data?.title}
              </span>
              {context?.type === "resource" && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "4px",
                  }}
                >
                  <span className={styles.resource_class}>Resource ID</span>
                  <span className={styles.resorce_id_name}>
                    {data?.resource?.resourceId || "--"}
                  </span>
                </div>
              )}
            </div>
            {/* ----------------second part ---------------------------- */}
            <div className={clsx(styles.first_data, styles.search_first_part)}>
              <div className={styles.align_center}>
                <span className={styles.category_name}>Total cost</span>
                <span className={styles.LDaysDiv}>
                  Last {context?.daysDifference} Days
                </span>
              </div>
              <div className={styles.align_center}>
                <span className={styles.total_cost_value}>
                  <ToolTipComponent data={data?.totalCost || 0}>
                    <span style={{ cursor: "context-menu" }}>
                      {" "}
                      {formattedTotalCost}
                    </span>
                  </ToolTipComponent>
                </span>
                <span
                  className={
                    data?.percentCostChange >= 0
                      ? styles.green_box
                      : styles.red_boxx
                  }
                >
                  {data?.percentCostChange >= 0 ? "Down by" : "Up by"}{" "}
                  {Math.abs(data?.percentCostChange || 0).toFixed(2)}%
                </span>
              </div>
              <div className={styles.align_center}>
                <span className={styles.average_name}>Average Daily Costs</span>
                <span className={styles.average_data}>
                  {" "}
                  <ToolTipComponent data={data?.averageDailyCost || 0}>
                    <span style={{ cursor: "context-menu" }}>
                      {" "}
                      {formattedAvgCost}
                    </span>
                  </ToolTipComponent>
                </span>
              </div>
            </div>
            {/* -----------------third part --------------------------------- */}
            <div
              className={clsx(styles.first_data, styles.search_first_part)}
              style={{ paddingRight: "6%" }}
            >
              <span className={styles.category_name}>
                Potential Monthly Savings
              </span>
              <span className={styles.cost_data}>
                USD
                <ToolTipComponent data={data?.potentialSavings || 0}>
                  <span style={{ cursor: "context-menu" }}>
                    {" "}
                    {formattedPotentialCost}
                  </span>
                </ToolTipComponent>
              </span>
            </div>
          </div>
          {context?.type === "resource" && (
            <div className={styles.Tags_column}>
              {tags.map((tag, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "4px",
                    paddingRight: "6%",
                  }}
                >
                  <span className={styles.headder}>{tag.label || "--"}</span>
                  <span className={styles.haeder_values}>
                    {tag.value || "--"}
                  </span>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default SearchHeader;
