import { useContext } from "react";
import config from "../../config";
import { useQuery } from "@tanstack/react-query";
import { AppContext } from "@/App.context";


// {
// 	snapshotsRecommendations: {
// 		snapshotsCount: number;
// 		taggedCount: number;
// 		taggedCost: number;
// 		untaggedCount: number;
// 		untaggedCost: number;
// 		orphanedCount: number;
// 		savings: number;
// 		snapshots: [{
// 			snapshotName: string;
// 			snapshotId: string;
// 			cost: number;
// 			age: number;
// 		}];
// 	},
// 	rightsizingRecommendations: {
// 		totalRecommendations: number;
// 		downsizeCount: number;
// 		downsizeCost: number;
// 		upsizeCount: number;
// 		upsizeCost: number;
// 		recommendations: [{
// 			savingsAvailable: string;
// 			recommendedType: string;
// 			currentCost: number;	
// 			currentType: string;
// 			recommendationType: string;
// 		}]
// 	},
// 	idleResourcesRecommendations: {
// 		idleCount: number;
// 		savingsAvailable: number;
// 		resources: [{
// 			resourceId: string;
// 			region: string;
// 			cost: number;
// 			type: string;
// 		}]
// 	}
// }

/**
 * @typedef GetAllStatistics
 * @property {snapshotsRecommendations} snapshotsRecommendations
 * @property {rightsizingRecommendations} rightsizingRecommendations
 * @property {idleResourcesRecommendations} idleResourcesRecommendations
 */


/**
 * @typedef snapshotsRecommendations 
 * @property {number} snapshotsCount
 * @property {number} taggedCount
 * @property {number} taggedCost
 * @property {number} untaggedCount
 * @property {number} untaggedCost
 * @property {number} orphanedCount
 * @property {number} savings
 * @property {GetSnapshots} snapshots
 */
/**
 * @typedef GetSnapshots
 * @property {string} snapshotName
 * @property {string} snapshotId
 * @property {number} cost
 * @property {number} age
 */

/**
 * @typedef rightsizingRecommendations
 * @property {number} totalRecommendations
 * @property {number} downsizeCount
 * @property {number} downsizeCost
 * @property {number} upsizeCount
 * @property {number} upsizeCost
 * @property {GetRecommendations} recommendations
 */
/**
 * @typedef GetRecommendations
 * @property {number} currentCost
 * @property {number} savingsAvailable
 * @property {string} recommendedType
 * @property {string} currentType
 * @property {string} recommendationType
 */

/**
 * @typedef idleResourcesRecommendations
 * @property {number} idleCount
 * @property {number} savingsAvailable
 * @property {GetIdle} resources
 */
/**
 * @typedef GetIdle
 * @property {string} resourceId
 * @property {string} region
 * @property {number} cost
 * @property {string} type
 */


/**
 * @typedef GetAllStatisticsRes
 * @property {GetAllStatistics} data
 */

/**
 * @typedef GetAllStatisticsReq
 * @property {'aws' | 'gcp' | 'azure' | 'allclouds' } service
 * @property {string} fromDate
 * @property {string} toDate
 * @property {string} type
 * @property {string} name
 * @property {string} accounts
 * @property {string} regions
 */

export const getRecommendationSearch = (token) => {
  /**
   * @param {GetAllStatisticsReq} req
   * @param {AbortSignal=} signal
   * @returns {Promise<GetAllStatisticsRes>}
   */
  return async (
    { service, fromDate, toDate, accounts, regions, type, name, spendingBy, serviceName },
    signal
  ) => {
    const body = {
      accounts: accounts || [],
      regions: regions || [],
      fromDate: fromDate,
      toDate: toDate,
      type: type,
      name: name,
      spendingBy:spendingBy,
      service: type === "resource" ? serviceName : undefined,
    };
    const statistics = await fetch(
      `${config.apiHost}/v1/${service}/search/search-result/recommendations`,
      {
        method: "POST",
        body: JSON.stringify(body),
        signal,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    if (!statistics.ok) {
      throw new Error((await statistics.json()).message);
    }
    const response = await statistics.json();
    return response;
  };
};

/**
 * @param {GetAllStatisticsReq} req
 * @param {any[]=} deps
 */
export const useRecommendationsSearch = (req, deps = []) => {
  const appContext = useContext(AppContext);
  const query = useQuery({
    queryKey: ["getRecommendationSearch", req, ...deps],
    queryFn: ({ signal }) => getRecommendationSearch(appContext.idToken)(req, signal),
  });
  return query;
};
