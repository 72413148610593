import { AppContext } from "@/App.context";
import config from "@/config";
import {
  API_LOADING_ERROR,
  API_NO_DATA_FOUND_ERROR,
  API_NO_DATA_FOUND_FOR_FILTERS_ERROR,
  API_NOT_FOUND_ERROR,
  ERROR_STATUS_CODES,
} from "@/lib/errors/error-constants";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";

/**
 * @typedef GetAllStatisticsFilters
 * @property {keyof typeof AWS_TAGS | keyof typeof AZURE_TAGS} filterOn
 * @property {'include' | 'exclude'} filterType
 * @property {string[]} values
 */

/**
 * @typedef AllTableStatistics
 * @property {AlltableStatics[]} snapshots
 */

/**
 * @typedef AlltableStatics
 * @property {String} account
 * @property {String} snapshotName
 * @property {String} snapshotId
 * @property {String} region
 * @property {String} diskId
 * @property {Number} diskSize
 * @property {Number} snapshotAge
 * @property {String} snapshotClass
 */

/**
 * @typedef AllBodyStatisticsRes
 * @property {AllTableStatistics} statistics
 */

/**
 * @typedef GetSnapshotsFilters
 * @property {string[]} accounts
 * @property {string[]} regions
 * @property {string[]} diskIds
 * @property {string[]} snapshotAge
 * @property {number=} minAge
 * @property {number=} maxAge
 * @property {string[]} tags
 * @property {string[]} resourceGroupNames
 */

/**
 * @typedef GetStatisticsReq
 * @property {'aws' | 'azure' | 'all-cloud' } service
 * @property {GetSnapshotsFilters} filters
 * @property {number} page
 * @property {number} limit
 * @property {string} orderBy
 * @property {"asc" | "desc"} order
 *
 */

export const getStatistics = (token) => {
  /**
   * @param {GetStatisticsReq} req
   * @returns {Promise<AllBodyStatisticsRes>}
   */
  return async ({ status,service, page, limit, filters, order, orderBy }, signal) => {
    const statusMap = {
      'Active': 'active',
      'In Progress': 'in_progress'
    };
    const mappedStatus = statusMap[status] || status;
    const owners = await fetch(
      `${config.apiHost}/v1/${service}/snapshots/get-all?status=${mappedStatus}`,
      {
        method: "POST",
        signal,
        body: JSON.stringify({
          page,
          limit,
          filters,
          order,
          orderBy,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    if (!owners.ok) {
      if (ERROR_STATUS_CODES.includes(owners.status)) {
        throw new Error(API_LOADING_ERROR);
      }
      if (owners.status === 404) {
        throw new Error(API_NOT_FOUND_ERROR);
      }
      throw new Error((await owners.json()).message);
    }
    const res = await owners.json();
    if (res?.snapshots?.length === 0) {
      if (
        filters?.accounts?.length ||
        filters?.filterOrphaned === true ||
        filters?.filterUntagged === true ||
        filters?.filterLegallyHeld === true ||
        filters?.diskIds?.length ||
        filters?.regions?.length ||
        filters?.resourceGroupNames?.length ||
        filters?.tags?.length ||
        filters?.maxAge > 0 ||
        filters?.minAge > 0 ||
        filters?.snapshotAge?.length
      ) {
        throw new Error(API_NO_DATA_FOUND_FOR_FILTERS_ERROR);
      }
      throw new Error(API_NO_DATA_FOUND_ERROR);
    }
    return res;
  };
};

/**
 * @param {GetStatisticsReq} req
 * @param {any[]=} deps
 */
export const useSnapTableStatistics = (req, deps = []) => {
  const appContext = useContext(AppContext);
  const query = useQuery({
    queryKey: ["getStatistics", req, ...deps],
    queryFn: ({ signal }) => getStatistics(appContext.idToken)(req, signal),
    retry:false
  });
  return query;
};
