import React, { useContext, useEffect, useMemo } from "react";
import { AppContext } from "@/App.context";
import { useNavigate, useLocation } from "react-router-dom";
import Login from "@/page-components/loginPage/login";
import { Navigate } from "react-router-dom";
import config from "@/config";

/**
 * @param props Props for auth guard
 */
export const AuthGuard = (props) => {
  const appContext = useContext(AppContext);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!appContext?.isLoggedIn) {
      const urlSearchParams = new URLSearchParams(location.search);
      const redirectUrl = encodeURIComponent(
        location.pathname + "?" + urlSearchParams.toString()
      );
      navigate(`/?redirectTo=${redirectUrl}`, { replace: true });
    }
    if (
      appContext?.isLoggedIn &&
      props.roles &&
      appContext?.user?.roles !== props.roles
    ) {
      navigate(`/all-clouds/dashboard`, { replace: true });
    }
  }, [appContext?.isLoggedIn, appContext?.user?.roles, navigate, props.roles]);

  if (appContext?.isAuthLoading) {
    return <Login />;
  }

  return <>{props.children}</>;
};

export const AuthGuardWithRole = (roles) => {
  return (props) => <AuthGuard roles={roles} {...props} />;
};

/**
 * @typedef RedirectForServiceProps
 * @property {string} service
 * @property {string} path
 * @property {React.ReactNode} children
 */

/**
 * @param {RedirectForServiceProps} props
 */
export const RedirectForService = ({ path, children }) => {
  const [service, updatedPath] = useMemo(() => {
    const service = path.split("/").at(1);
    const updatePath = path.substring(path.indexOf(service) + service.length);
    return [service, updatePath];
  }, [path]);

  const redirectCloud = useMemo(() => {
    if (
      service === "aws" ||
      service === "gcp" ||
      service === "azure" ||
      service === "all-clouds"
    ) {
      if (
        config.enabledClouds.size > 0 &&
        config.enabledClouds.has(service) === false
      ) {
        if (config.enabledClouds.size === 1) {
          return Array.from(config.enabledClouds).at(0);
        } else {
          return "all-clouds";
        }
      }
    }
  }, [service]);

  if (redirectCloud?.length && redirectCloud !== service) {
    return <Navigate to={`/${redirectCloud}${updatedPath}`} replace={true} />;
  }

  return <>{children}</>;
};
