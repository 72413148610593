import React, { useRef } from "react";
import Barchart from "./barChar";
// import { chartdata } from "../chart.data";
import download from "@/assets/img/downloadButton.svg";
import { useMemo } from "react";
import { useHeaderStatistics } from "@/services/snapshots/getAllCloudHeader.repo";
import styles from "@/page-components/snapshot/snapshots.module.css";
import { CSVLink } from "react-csv";
// import BarGraphErrorStates from "@/components/States/BarGraphErrorStates";
import DataAbsense from "@/components/States/DataAbsense";
import DataLoading from "@/components/States/DataLoading";
import VerticalBarGraph from "@/components/States/verticalBarErrorState";
import ScenariosErrorState from "@/components/States/scenariosErrorState";
import {
  API_LOADING_ERROR_CUSTOM_WIDGET,
  API_LOADING_ERROR_SNAPSHOT,
  API_NO_DATA_FOUND_ERROR_CUSTOM_WIDGET,
  API_NOT_FOUND_ERROR,
  API_NOT_FOUND_ERROR_CUSTOM_WIDGET,
} from "@/lib/errors/error-constants";
import CurrencyFormatter from "@/lib/value-converison";
import { ToolTipComponent } from "@/page-components/cost-explorer/components/cost-explorer-header.component";

const SnapChartSection = ({ service, name }) => {
  const query = useMemo(() => {
    return {
      service,
    };
  }, [service]);

  const { data, isLoading, error } = useHeaderStatistics(query);
  const csvLinkRef = useRef(null);
  const handleDownloadCsv = () => {
    csvLinkRef.current.link.click();
  };
  return (
    <>
      {isLoading && <VerticalBarGraph />}
      {error && (
        <ScenariosErrorState
          error={error.message}
          messageConfig={{
            [API_LOADING_ERROR_CUSTOM_WIDGET]: {
              message: "Oops!",
              additionalMessage:
                "We encountered a problem loading your data. Please ensure a stable internet connection and try again. If the issue persists, contact support.",
            },
            [API_NOT_FOUND_ERROR_CUSTOM_WIDGET]: {
              message: "404 Error",
              additionalMessage:
                "We couldn’t find the data you’re looking for. This might be due to a temporary issue or incorrect resource. Please check back later or contact support if the problem persists.",
            },
            [API_NO_DATA_FOUND_ERROR_CUSTOM_WIDGET]: {
              message: "No Data Available",
              additionalMessage:
                "We couldn’t retrieve data at this moment. This might be due to an absence of data or a temporary issue. Please try again later.",
            },
          }}
        />
      )}

      {data && (
        <div className={styles.chart_container}>
          <div className={styles.type_cost}>
            <div
              style={{
                width: "91%",
                display: "flex",
                flexDirection: "column",
                gap: "4px",
              }}
            >
              <span className={styles.snap_cloud_text}>{name}</span>
              <div style={{ display: "flex", gap: "8px" }}>
                <span className={styles.cost_text}>Cost</span>
                <span className={styles.cost_value}>
                  <ToolTipComponent data={data?.stats?.totalCost}>
                  {data?.stats?.totalCost !== null ? (
                    <CurrencyFormatter
                      value={data?.stats?.totalCost}
                      hideDollar={false}
                    />
                  ) : (
                    "--"
                  )}
                  </ToolTipComponent>
                </span>
              </div>
            </div>
            <span className={styles.bownloadButton}>
              <img src={download} alt="" onClick={handleDownloadCsv} />
              <CSVLink
                data={[
                  {
                    Category: "7-15 Days",
                    Value: data?.stats?.days_7_to_15?.count || 0,
                  },
                  {
                    Category: "16-30 Days",
                    Value: data?.stats?.days_16_to_30?.count || 0,
                  },
                  {
                    Category: "More Than 31 Days",
                    Value: data?.stats?.days_31_or_more?.count || 0,
                  },
                ]}
                filename={`${service}-graphData.csv`}
                target="_blank"
                ref={csvLinkRef}
                style={{ display: "none" }}
              >
                Download
              </CSVLink>
            </span>
          </div>
          <div className="chart">
            <div className="vertical-bar-chart">
              <Barchart Bardata={data?.stats || 0} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SnapChartSection;
