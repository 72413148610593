import React, { useState } from "react";
import styles from "@/components/state-change/state.module.css";
import cloudService from "@/page-components/cost-explorer/components/usage-details/cloud.service";
import clsx from "clsx";
import { ToolTipComponent } from "@/page-components/cost-explorer/components/cost-explorer-header.component";
import CurrencyFormatter from "@/lib/value-converison";
// import removeIcon from "@/assets/img/filter-delete.svg";

const AcceptJiraTable = ({ selectedRows, onRowsChange, table_account }) => {
  // const [newSelectedRows, setNewSelectedRows] = useState(selectedRows || []);
  // const handleRemoveRow = (indexToRemove) => {
  //   const updatedRows = newSelectedRows.filter((_, index) => index !== indexToRemove);
  //   setNewSelectedRows(updatedRows);
  //   onRowsChange(updatedRows); // Notify parent about the change
  // };
  return (
    <div
      style={{
        width: "100%",
        // background: "#F8F9FA",
        display: "flex",
        flexDirection: "column",
        gap: "12px",
        paddingTop: "20px",
      }}
    >
      <span className={styles.selected_resource_table}>
        Selected Resources ({selectedRows?.length || 0})
      </span>
      <div
        style={{ maxHeight: "350px", overflowY: "auto", position: "relative" }}
      >
        <table
          className="table table-hover"
          id={styles.customers}
          style={{ width: "100%" }}
        >
          <thead>
            <tr
              style={{
                position: "sticky",
                top: 0,
                backgroundColor: "#fff",
                zIndex: 1,
              }}
            >
              {/* <th className={clsx(styles.th_class, styles.table_td_width)}>
                <img src={removeIcon} alt="Remove"></img>
              </th> */}
              <th className={clsx(styles.th_class, styles.table_td_width)}>
                {table_account}
              </th>
              <th className={clsx(styles.th_class, styles.table_td_width)}>
                Resource Name
              </th>
              <th className={clsx(styles.th_class, styles.table_td_width)}>
                Resource Type
              </th>
              <th
                className={clsx(styles.th_class, styles.table_td_width)}
                style={{ textAlign: "right" }}
              >
                <span>Projected Savings</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {(selectedRows || []).map((item, index) => (
              <tr key={index} className={clsx(styles.table_row, "p-0")}>
                {/* <td className={styles.table_data__Class}>
               
            <img
              src={removeIcon}
              alt="Remove"
              onClick={() => handleRemoveRow(index)}
              style={{ cursor: "pointer" }}
            />
          </td> */}

                <td
                  className={styles.table_data__Class}
                  title={item?.account_name}
                >
                  {item?.account_name || "NA"}
                </td>
                <td
                  className={styles.table_data__Class}
                  title={item?.idle_resource_name}
                >
                  {item?.idle_resource_name || item?.name || "NA"}
                </td>
                <td className={styles.table_data__Class}>
                  {item?.resource_type || "NA"}
                </td>
                <td
                  className={styles.table_data__Class}
                  style={{
                    color: "var(--Success-Text, #127E24)",
                    fontWeight: 600,
                    fontSize: 13,
                    paddingRight: "8px",
                    textAlign: "right",
                  }}
                >
                  <ToolTipComponent data={item?.saving || 0}>
                    {item?.saving < 0.1 && "< $0.1"}
                    {item?.saving > 0.1 && (
                      <CurrencyFormatter value={item?.saving || 0} />
                    )}
                  </ToolTipComponent>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AcceptJiraTable;
