// IdleIndividualBarGraph.js
import React, { useRef, useMemo } from "react";
import {
  ResponsiveContainer,
  BarChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Bar,
  Label,
} from "recharts";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useCardStatistics } from "@/services/IdleResources/getCardStatistics.repo";
import { Spinner } from "react-bootstrap";
import styles from "@/page-components/idle-resource/idle.module.css";
import clsx from "clsx";


const IdleIndividualBarGraph = ({ data, serviceType }) => {
  const chartRef = useRef(null);
  return (
    <>
    {data && (
    <div className={clsx("col-sm-6 col-md-4", styles.cost_graph)}>
      
        <div className="d-flex justify-content-between align-items-center w-100">
          <div className={styles.aws_costs_div}>
            <div className={styles.aws_cost_name1}>{data.title}</div>
            <div style={{ display: "flex", flexDirection: "row", gap: "12px" }}>
              <div
                style={{
                  color: "var(--neutral-d-3, #495057)",
                  fontFamily: "Inter",
                  fontSize: "12px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "120%",
                }}
              >
                Potential Savings
              </div>
              <div className={styles.aws_cost_value}>{data.costValue}</div>
            </div>
          </div>
          <div className="d-flex align-items-start gap-2 ms-auto"></div>
        </div>
      
      <div className="row w-100">
        <div className="col-sm-12 p-0">
          <div className={styles.vertical_bar_chart} ref={chartRef}>
            <ResponsiveContainer width="100%" height={175}>
              <BarChart
                barSize={16}
                data={data.data1}
                layout="vertical"
                margin={{ top: 0, right: 15, bottom: 10, left: -16 }}
              >
                <XAxis
                  type="number"
                  axisLine={false}
                  tickLine={false}
                  stroke="#A3A3A3"
                  fontSize={10}
                  fontWeight={400}
                  fontFamily="Helvetica"
                  tickFormatter={(value) => `$${value}`}
                />
                <YAxis
                  dataKey="name"
                  type="category"
                  axisLine={false}
                  tickLine={false}
                  width={120}
                  fontSize={10}
                  fontWeight={400}
                  fontFamily="Helvetica"
                  color="#495057"
                />

                <CartesianGrid strokeDasharray="0" stroke="#F0F0F0" />

                <Bar
                  dataKey="uv"
                  fill="red"
                  shape={(props) => (
                    <CustomBar
                      {...props}
                      Image={props.payload.Image}
                      serviceType={serviceType}
                      type={props.payload.type}
                    />
                  )}
                ></Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    </div>
    )}
  </>

  );
};

const CustomBar = ({
  x,
  y,
  width,
  height,
  value,
  fill,
  name,
  Image,
  serviceType,
  type,
}) => {
  const dataQuery = useMemo(() => {
    return {
      service: serviceType,
      resourceType: type,
    };
  }, [serviceType, type]);

  const { data, isLoading } = useCardStatistics(dataQuery);

  const idle_data = [
    {
      name: "More Than 31 Days",
      value: data?.count["31_more"]?.count || 0,
      color: "#DE3E11",
    },
    {
      name: "16-30 Days",
      value: data?.count["15_30"]?.count || 0,
      color: "#ED9615",
    },
    {
      name: "8-15 Days",
      value: data?.count["7_15"]?.count || 0,
      color: "#F8C455",
    },
    {
      name: "0-7 Days",
      value: data?.count["0_7"]?.count || 0,
      color: "#33A02C",
    },
  ];
  const totalValue = idle_data.reduce((acc, entry) => acc + entry.value, 0);

  return (
    <OverlayTrigger
      placement="top"
      overlay={
        <Tooltip
          id={`tooltip_${value}`}
          style={{
            backgroundColor: "black",
            margin: "0px",
            padding: "0px",
            position: "absolute",
            borderRadius: "8px",
            opacity:1
          }}
        >
          {isLoading && <Spinner />}
          {data && (
            <div
              style={{
                marginLeft: "-8px",
                marginRight: "-8px",
                marginTop: "-4px",
                borderRadius: "6px 6px 0px 0px",
                opacity:1
              }}
            >
              <div
                className={styles.tooltip_css_boot}
                style={{
                  borderRadius: "6px 6px 0px 0px",
                }}
              >
                <span className={styles.tooltip_name}>
                  <img
                    src={Image}
                    alt="Icon"
                    style={{
                      marginRight: "8px",
                      width: "20px",
                      height: "20px",
                    }}
                  />
                  {name}
                </span>
              </div>
              <div style={{ padding: "8px" }}>
                <table>
                  <tbody>
                    {idle_data
                      .slice()
                      .reverse()
                      .map((entry, index) => (
                        <tr key={`legend-${index}`}>
                          <td
                            style={{
                              textAlign: "left",
                              display: "flex",
                              gap: "6px",
                              alignItems: "center",
                            }}
                          >
                            <div
                              style={{
                                width: "8px",
                                height: "8px",
                                backgroundColor: entry.color,
                                display: "inline-block",
                              }}
                            ></div>
                            <span className={styles.td_data}>{entry.name}</span>
                          </td>
                          <td></td>
                          <td></td>

                          <td
                            style={{
                              textAlign: "right",
                              padding: "2px 4px 4px 8px",
                              display: "flex",
                              alignItems: "center",
                              gap: "8px",
                            }}
                          >
                            <strong className={styles.td_data} style={{}}>
                              {entry.value}
                            </strong>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                  <tfoot
                    style={{
                      textAlign: "left",
                      borderTop: "1px solid #2B2B2B",
                    }}
                  >
                    <tr>
                      <td
                        className={styles.td_data}
                        style={{ textAlign: "left" }}
                      >
                        Total
                      </td>
                      <td></td>
                      <td></td>

                      <td
                        className={styles.td_data}
                        style={{ paddingRight: "4px" }}
                      >
                        {totalValue}
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          )}
        </Tooltip>
      }
    >
      <rect x={x} y={y} width={width} height={height} fill={fill} />
    </OverlayTrigger>
  );
};

export default IdleIndividualBarGraph;
