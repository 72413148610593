import React, { useState, useMemo, useContext, useEffect } from "react";
import CostExplorerContainer from "@/page-components/cost-explorer/components/cost-explorer-container.component";
import CostBarGraph from "./parts/aws-cost-bar-graph.part";
import ServiceTable from "./parts/aws-service-table.part";
import format from "date-fns/format";
import FilterSection, {
  FilterContextProvider,
} from "../components/filter-section/filter-section.component";
import CustomWidget from "../components/custom-widget/custom-widget.component";
import parse from "date-fns/parse";
import differenceInDays from "date-fns/differenceInDays";
import { useAllStatistics } from "@/services/costExplorer/getStatistics.repo";
import DataLoading from "@/components/States/DataLoading";
import { FilterContext } from "@/page-components/cost-explorer/components/filter-section/filter-section.component";
import { useParams } from "react-router-dom";
import { useCombineQuery } from "@/services/budget/setDelete";
import TagUntaggedComponent from "../components/tag-untagged.part";
import HeaderState from "@/components/States/HeaderState";
import { endOfDay, startOfDay } from "date-fns";
import { useAllDimensions } from "@/services/costExplorer/getAllDimesionCE.repo";
import ForecastPage from "../components/forecast-cost/forecast.page";

const AwsDashboard = () => {
  return (
    <FilterContextProvider>
      <AwsDashboardComp />
    </FilterContextProvider>
  );
};

const AwsDashboardComp = () => {
  const [selectedCustomTabs, setSelectedCustomTabs] = useState(false);
  const [selectedForecastTab, setSelectedForecastTab] = useState(false);
  const filterContext = useContext(FilterContext);
  const params = useParams();
  const { BudgetName, Id } = params;

  const { mutate: Body, data: Details } = useCombineQuery();

  useEffect(() => {
    Body({
      body: { accountId: Id, budgetName: BudgetName },
      service: "aws",
      selection: "singleRes",
    });
  }, [Body, BudgetName, Id, params]);

  const { fromDate, toDate } = {
    fromDate: format(
      startOfDay(filterContext?.range[0].startDate),
      "dd-MM-yyyy"
    ),
    toDate: format(endOfDay(filterContext?.range[0].endDate), "dd-MM-yyyy"),
  };

  const date2 = parse(toDate, "dd-MM-yyyy", new Date());
  const date1 = parse(fromDate, "dd-MM-yyyy", new Date());

  const formattedDate1 = date1.toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "short",
  });
  const formattedDate2 = date2.toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "short",
  });

  const daysDifference = `${formattedDate1} - ${formattedDate2}`;

  const query = useMemo(() => {
    return {
      service: "aws",
      fromDate,
      toDate,
    };
  }, [fromDate, toDate]);

  const { data: statisticData, isLoading, isError } = useAllStatistics(query);

  const budgetDetails = useMemo(() => {
    function manipulateBudgetData(data) {
      const subscriptionName = data?.budgetDetails?.AccountId;
      const subscriptionDimensionExists = data?.filters?.dimensions.some(
        (dimension) => dimension.name === "AccountId"
      );
      if (!subscriptionDimensionExists) {
        const newDimension = {
          name: "AccountId",
          values: [subscriptionName],
        };

        data?.filters?.dimensions.push(newDimension);
      }
      return data;
    }

    const manipulatedData = manipulateBudgetData(Details);
    return { Details: manipulatedData, BudgetName: BudgetName, Id };
  }, [BudgetName, Details, Id]);

  useEffect(() => {
    const backButtonClicked = JSON.parse(
      localStorage.getItem("backButtonClicked")
    );

    // if (backButtonClicked === true) {
    let storage = localStorage.getItem("usageDetails");
    if (storage) {
      storage = JSON.parse(storage);
      if (storage.cloud !== "aws") return () => {};
      const parsedStorageToDate = endOfDay(
        parse(storage.toDate, "dd-MM-yyyy", new Date())
      );

      const parsedStorageFromDate = startOfDay(
        parse(storage.fromDate, "dd-MM-yyyy", new Date())
      );

      if (storage?.dimension)
        filterContext.setSelectedDimension(storage?.dimension);

      if (storage?.filter) filterContext.setSelectedFilters(storage.filter);

      if (storage?.tags) filterContext.setSelectedTags(storage.tags);

      if (storage?.showUntaggedOnly)
        filterContext.setUntagged(storage.showUntaggedOnly);

      if (storage?.showUntaggableOnly)
        filterContext.setUntagged(storage.showUntaggableOnly);

      if (storage?.granularity)
        filterContext.setSelectedGranularity(storage.granularity);

      filterContext?.setRange([
        { startDate: parsedStorageFromDate, endDate: parsedStorageToDate },
      ]);
    }
    localStorage.removeItem("usageDetails");
    // }
  }, []);

  const dimensoinQuery = useMemo(() => {
    return {
      service: "aws",
    };
  }, []);

  const { data: dimensionData } = useAllDimensions(dimensoinQuery);

  return (
    <>
      {isLoading && <HeaderState />}
      {isError && <DataLoading />}
      {statisticData && (
        <CostExplorerContainer
          selectedCustomTabs={selectedCustomTabs}
          setSelectedCustomTabs={setSelectedCustomTabs}
          service="aws"
          range={filterContext?.range}
          setRange={filterContext?.setRange}
          toDate={toDate}
          daysDifference={daysDifference}
          fromDate={fromDate}
          data={statisticData}
          setSelectedForecastTab={setSelectedForecastTab}
          selectedForecastTab={selectedForecastTab}
        />
      )}

      {!selectedCustomTabs && !selectedForecastTab ? (
        <>
          <div className="d-flex flex-row">
            <div
              className="d-flex flex-column py-4 content-wrapper-left"
              style={{
                width: "82%",
                gap: "24px",
              }}
            >
              <TagUntaggedComponent
                service="aws"
                toDate={toDate}
                fromDate={fromDate}
              />
              <>
                <CostBarGraph
                  service="aws"
                  toDate={toDate}
                  fromDate={fromDate}
                  daysDifference={daysDifference}
                  dimensionData={dimensionData}
                />

                <ServiceTable
                  service="aws"
                  toDate={toDate}
                  fromDate={fromDate}
                  dimension={filterContext?.selectedDimension}
                  filters={filterContext?.selectedFilters}
                  tags={filterContext?.selectedTags}
                  dimensionData={dimensionData}
                  tagname={filterContext?.selectedGroupTags}
                />
              </>
            </div>

            <div style={{ width: "17%" }}>
              <FilterSection
                service="aws"
                enableBudgetDetails={budgetDetails}
                dimensionData={dimensionData}
              />
            </div>
          </div>
        </>
      ) : selectedCustomTabs && !selectedForecastTab ? (
        <div className="content-wrapper pt-4" style={{ marginBottom: "32px" }}>
          <CustomWidget service="aws" dimensionData={dimensionData} />
        </div>
      ) : (
        <ForecastPage service="aws" accountName="Account" />
      )}
    </>
  );
};

export default AwsDashboard;
