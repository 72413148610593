import React, { useMemo, useContext } from "react";
import vertical_line from "@/assets/img/Vector 5.svg";
import styles from "@/page-components/snapshot/snapshots.module.css";
import style from "@/page-components/RightSizing/rightSizing.module.css";

import clsx from "clsx";
import { formatCount, ToolTipComponent } from "@/page-components/cost-explorer/components/cost-explorer-header.component";

import HeaderState from "@/components/States/HeaderState";
import ScenariosErrorState from "@/components/States/scenariosErrorState";
import {
  API_LOADING_ERROR,
  API_LOADING_ERROR_SNAPSHOT,
  API_NO_DATA_FOUND_ERROR_CUSTOM_WIDGET,
  API_NOT_FOUND_ERROR,
  API_NOT_FOUND_ERROR_CUSTOM_WIDGET,
} from "@/lib/errors/error-constants";
import { SnapshotContext } from "../../snap.contex";

function AWSHeaderSectionHistory({ service, data, selectedOption, error, isLoading }) {
  const context = useContext(SnapshotContext);

  const savedOnDeletion = formatCount(
    Number((data?.data?.saved_on_deleted || 0).toFixed(2)) || 0
  );

  return (
    <>
      {isLoading && data === undefined && (
        <>
          <div style={{ background: "white", borderRadius: "8px" }}>
            <HeaderState />
          </div>
        </>
      )}

      {error && (
        <ScenariosErrorState
        headerItem="header"
          error={error.message}
          messageConfig={{
            [API_LOADING_ERROR_SNAPSHOT]: {
              message: "Oops!",
              additionalMessage:
                "We encountered a problem loading your data. Please ensure a stable internet connection and try again. If the issue persists, contact support.",
            },
            [API_NOT_FOUND_ERROR_CUSTOM_WIDGET]: {
              message: "404 Error",
              additionalMessage:
                "We couldn’t find the data you’re looking for. This might be due to a temporary issue or incorrect resource. Please check back later or contact support if the problem persists.",
            },
            [API_NO_DATA_FOUND_ERROR_CUSTOM_WIDGET]: {
              message: "No Data Available",
              additionalMessage:
                "We couldn’t retrieve data at this moment. This might be due to an absence of data or a temporary issue. Please try again later.",
            },
          }}
        />
      )}

      {!isLoading && !error && data && (
        <div className={clsx("row", styles.snap_sec_div)}>
          <div className={styles.snapshots_by_types} style={{width:"70%"}}>
            <div className={styles.snapshots_main} style={{ width: "49%" ,borderRight:"1px solid #DEE2E6" }}>
              <span className={styles.snapshot_text}>SNAPSHOTS DELETED</span>
              <span className={styles.snapshot_value}>
                {data?.data.deleted || 0}
              </span>
            </div>
       
            {/* <img src={vertical_line} alt="" style={{ height: "100%" }} /> */}

            <div className={styles.untagged_snapshot_main}>
              <span className={styles.snapshot_text}>SNAPSHOTS ADDED</span>
              <span className={styles.snapshot_value}>
                {data?.data.added || 0}
              </span>
            </div>
          </div>
            <div
                className={clsx(style.greenColor,
                  style.rec_second_div_third_container
                )}
                style={{ width: "30%" }}
              >
                <div className="container-fluid innerContainer-1">
                  <div className="container innerContainer-div">
                    <div className="container innerContainer-text">
                      <div className="container innerContainer-text-1">
                      SAVED ON SNAPSHOTS DELETION
                      </div>
                      <div
                        className="container innerContainer-text-2"
                        style={{ display: "flex", gap: "4px" }}
                      >
                        {data?.data?.saved_on_deleted >= 0 ? (
                          <ToolTipComponent data={data?.data?.saved_on_deleted || 0}>
                            <span style={{ cursor: "context-menu" }}>
                              {savedOnDeletion}
                            </span>
                          </ToolTipComponent>
                        ) : (
                          <ToolTipComponent data={data?.data?.saved_on_deleted|| 0}>
                            <span style={{ cursor: "context-menu" }}>
                              {savedOnDeletion}
                            </span>
                          </ToolTipComponent>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
        </div>
      )}
    </>
  );
}

export default AWSHeaderSectionHistory;
