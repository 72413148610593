import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { CustomTooltip } from "./idle-history-bar-graph.part";

const HistoryLineChart = ({ lineChartData, colorMap }) => {
  return (
    <ResponsiveContainer width="100%" height={300}>
      <LineChart
        data={lineChartData}
        margin={{ top: 0, right: 3, left: -13, bottom: 0 }}
      >
        <CartesianGrid strokeDasharray="0" vertical={false} stroke="#F0F0F0" />
        <XAxis
          dataKey="month"
          tickLine={false}
          axisLine={false}
          fontSize={10}
          fontWeight={500}
          stroke="var(--Neutral-D3, #495057)"
        />
        <YAxis
          label={{ fill: "#A3A3A3" }}
          tickLine={false}
          axisLine={false}
          fontSize={10}
          fontWeight={500}
          stroke="var(--Neutral-D3, #495057)"
        />
        <Legend
          align="center"
          verticalAlign="bottom"
          layout="horizontal"
          iconSize={12}
          iconType="Square"
          wrapperStyle={{}}
          fontSize={12}
          fontWeight={400}
          fontStyle="normal"
        />
        <Tooltip content={CustomTooltip} cursor={{ fill: "transparent" }} />
        <Line
          type="straight"
          dataKey="deleted"
          stroke="#056EE5"
          name="Idle Resources Deleted"
        />
        <Line
          type="straight"
          dataKey="added"
          stroke="#E8BF67"
          name="Idle Resources Added"
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default HistoryLineChart;
