import { AppContext } from "@/App.context";
import config from "@/config";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import { DownloadFile } from "@/lib/download-file";

/**
 * @typedef GetAllStatistics
 * @property {GetDataStatistics} data
 * @property {GetTotalCountStatistics} count
 */

/**
 * @typedef GetTotalCountStatistics
 * @property {number} total_data
 */

/**
 * @typedef GetDataStatistics
 * @property {string} account_name
 * @property {string} name
 * @property {string} id
 * @property {string} resource_group_name
 * @property {string} resource_type
 * @property {string} region
 * @property {string} owner
 * @property {number} idle_days
 * @property {string} resource_id
 * @property {number} saving
 * @property {string} status
 */

/**
 * @typedef GetIdleFilters
 * @property {string[]} accounts
 * @property {string[]} rg
 * @property {string[]} regions
 * @property {string[]} tags
 */

/**
 * @typedef GetAllStatisticsRes
 * @property {GetAllStatistics[]} statistics
 */
/**
 * @typedef GetStatisticsReq
 * @property {'aws' | 'gcp' | 'azure'  } service
 * @property {'ip' | 'disk' | 'vm' | 'lb' |'all'} resourceType
 * @property {GetIdleFilters} filters
 * @property {number} page
 * @property {number} recordsPerPage
 * @property {number} idleDays
 * @property {'true' | 'false'} desc
 * @property {'account_name' | 'name' | 'resource_group_name' |'resource_id' | 'resource_type' | 'region' | 'idle_days' | 'saving'} orderBy
 * @property { 'false' | 'true' } untagged
 */

// ------------------------------api for the saving details download table-------------------------------------------------------------
export const getSavingDetailsDownload = (token) => {
  return async (
    {
      service,
      page,
      resourceType,
      recordsPerPage,
      idleDays,
      desc,
      orderBy,
      filters,
      untagged,
      tableName,
      status,
    },
    signal
  ) => {
    const body = {
      accounts: filters?.accounts || [],
      regions: filters?.regions || [],
      tags: filters?.tags || [],
    };

    if (service === "azure") {
      body.rg = filters?.rg || [];
    }

    const url = new URL(
      `${config.apiHost}/v1/${service}/idle-resources/${tableName}-download?page=${page}&recordsPerPage=${recordsPerPage}&resourceType=${resourceType}&idleDays=${idleDays}&desc=${desc}&orderBy=${orderBy}&status=${status}`
    );

    if (untagged) {
      url.searchParams.append("untagged", "true");
    }

    const owners = await fetch(url.toString(), {
      method: "POST",
      body: JSON.stringify(body),
      signal,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    if (!owners.ok) {
      throw new Error((await owners.json()).message);
    }

    const reader = owners.body.getReader();
    const stream = new ReadableStream({
      start(controller) {
        function push() {
          reader.read().then(({ done, value }) => {
            if (done) {
              controller.close();
              return;
            }
            controller.enqueue(value);
            push();
          });
        }
        push();
      },
    });
    const blob = await new Response(stream).blob();
    const fileName = `Idle-${status}-data.csv`;
    DownloadFile(blob, fileName);
  };
};

/**

 * @param {GetStatisticsReq} req
 * @param {any[]=} deps
 */
export const UseSavingDetailsDownload = (req, deps = []) => {
  const appContext = useContext(AppContext);
  const mutation = useMutation({
    mutationKey: ["getSavingDetailsDownload", req, ...deps],
    mutationFn: ({ signal }) =>
      getSavingDetailsDownload(appContext.idToken)(req, signal),
  });
  return mutation;
};
