import React, { useContext, useEffect, useRef, useState } from "react";
import { IdleContext } from "../idle-context-file.part";
import Dropdown from "react-bootstrap/Dropdown";
import styles from "@/page-components/idle-resource/idle.module.css";
import { DropdownButton } from "react-bootstrap";
import nextIcon from "@/assets/img/savingNextIcon.svg";
import previousIcon from "@/assets/img/savingPrevYearIcon.svg";
import CustomCalendar from "./react-calendar";
import calender_side_arrow from "@/assets/img/calender_side_arrow.svg";
import { useSearchParams } from "react-router-dom";

const IdleDropdownHistory = ({ showDropdown }) => {
  const context = useContext(IdleContext);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
  
    useEffect(() => {
      const option = searchParams.get("option") || "Active";
      context?.setSelectedOption(option);
    }, [searchParams, context]);
    
    const handleDropdownChange = (option) => {
      context?.setSelectedOption(option);
  
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.set("option", option);
      setSearchParams(newSearchParams);
    };

  const dropdownValueMap = {
    "3month": "Last 3 months",
    "6month": "Last 6 months",
    "12month": "Last 12 months",
    thisyear: "This Year",
  };

  const handleSelectMonth = (displayValue) => {
    const internalValue = Object.keys(dropdownValueMap).find(
      (key) => dropdownValueMap[key] === displayValue
    );
    context?.setSelectMonthRange(internalValue || displayValue); 
    context?.setDropdownView("main");
  };

  const handleCustomRangeClick = () => {
    // context?.setSelectMonthRange("Custom"); 
    context?.setDropdownView("customRange");
    setIsDropdownOpen(true);
  };

  const handleBackClick = () => {
    context?.setDropdownView("main");
    context?.setRange([
      {
        startDate: "",
        endDate: "",
        key: "selection",
      },
    ]);
    context?.setSelectedDate(null)
    if (
      context?.range[0]?.startDate &&
      context?.range[0]?.endDate
    ) {
      context?.setSelectMonthRange("3month");
    }
  };

  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  const mainDropdownOptions = Object.values(dropdownValueMap).filter(
    (value) => value !== "Custom Month Range"
  ); 

    const dropdownRef = useRef(null);
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
          setIsDropdownOpen(false);
        }
      };
  
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [setIsDropdownOpen]);
  

  return (
    <div className={styles.header_dropdown}>
      <div className={styles.cal_dropdown}>
        <div className={styles.select_period_css}>Select Period</div>

        {context?.dropdownView === "main" && (
          <DropdownComponent
          dropdownRef={dropdownRef}
            // title={context?.selectMonthRange}
            // options={mainDropdownOptions}
            title={dropdownValueMap[context?.selectMonthRange] || "Last 3 Months"} 
            options={Object.values(dropdownValueMap)}
            onSelect={handleSelectMonth}
            onToggle={toggleDropdown}
            isOpen={isDropdownOpen}
            extraButton={{
              label: "Custom Month Range",
              onClick: handleCustomRangeClick,
              imgAfter: true,
            }}
          />
        )}

        {context?.dropdownView === "customRange" && (
          <DropdownComponent
          dropdownRef={dropdownRef}
            title={context?.selectedDate || dropdownValueMap[context?.selectMonthRange]}
            options={[]}
            onSelect={handleBackClick}
            onToggle={toggleDropdown}
            isOpen={isDropdownOpen}
            extraButton={{
              label: "Custom Months Range",
              onClick: handleBackClick,
              imgBefore: true,
            }}
          >
            <div
              style={{
                borderBottom: "1px solid #F8F9FA",
                marginBottom: "16px",
                marginTop: "8px",
              }}
            ></div>
            <CustomCalendar
              range={context?.range}
              setRange={context?.setRange}
              setIsDropdownOpen={setIsDropdownOpen}
            />
          </DropdownComponent>
        )}
      </div>

      {showDropdown && (
        <Dropdown onSelect={(option) => handleDropdownChange(option)}>
          <Dropdown.Toggle
            variant="none"
            id={styles.dropdown_header}
            style={{ color: "white" }}
          >
            {context?.selectedOption}
          </Dropdown.Toggle>
          <Dropdown.Menu className={styles.dropdown_menu}>
            <Dropdown.Item className={styles.dropdown_item} eventKey="Active">
              Active
            </Dropdown.Item>
            <Dropdown.Item className={styles.dropdown_item} eventKey="Retained">
              Retained
            </Dropdown.Item>
            <Dropdown.Item className={styles.dropdown_item} eventKey="History">
              History
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      )}
    </div>
  );
};

export default IdleDropdownHistory;

const DropdownComponent = ({
  options,
  onSelect,
  title,
  onToggle,
  isOpen,
  extraButton,
  children,
  dropdownRef
}) => {
  const context = useContext(IdleContext);
  return (
    <div className={styles.dropdown_custom}>
      <button className={styles.dropdown_custom_button} onClick={onToggle}>
        {title}
        <span style={{ paddingLeft: "10px" }}>
          <img src={calender_side_arrow} alt="icon"></img>
        </span>
      </button>
      {isOpen && (
        <div
        ref={dropdownRef}
          className={styles.dropdown_menuss}
          style={{
            width: context?.dropdownView === "customRange" ? "300px" : "190px",
          }}
        >
          {options.map((option, index) => (
            <button
              key={index}
              className={styles.dropdown_itemed}
              onClick={() => {
                onSelect(option);
                onToggle();
              }}
            >
              {option}
            </button>
          ))}
            {extraButton.label === "Custom Month Range" && (
              <div
                style={{
                  borderTop: "1px solid #F8F9FA",
                  marginBottom: "4px",
                  marginTop: "4px",
                }}
              ></div>
            )}
          {extraButton && (
            <button
              className={styles.dropdown_itemed}
              // style={{ marginTop: "4px", borderTop: "1px solid #F8F9FA" }}
              onClick={extraButton.onClick}
            >
              {extraButton.imgBefore && (
                <img
                  src={previousIcon}
                  alt=""
                  style={{ paddingRight: "10px" }}
                />
              )}
              {extraButton.label}
              {extraButton.imgAfter && (
                <img src={nextIcon} alt="" style={{ paddingLeft: "19px" }} />
              )}
            </button>
          )}
          {children}
        </div>
      )}
    </div>
  );
};
