import React, { useContext, useEffect, useMemo, useState } from "react";
import style from "@/page-components/RightSizing/rightSizing.module.css";
import modulestyle from "@/page-components/RI-Capacity/ri.module.css";
// import notification from "@/assets/img/notification-cross.svg";
import crossModal from "@/assets/img/modal_cross.svg";
import infoIcon from "@/assets/img/vmRec_i_icon.svg";
import { RiContext } from "@/page-components/RI-Capacity/ri.context";
import { Offcanvas, Spinner } from "react-bootstrap";
import ScopeModalTable from "../scope-modal-table.part";
import InstanceComponent from "./instance-component.part";
import { useScopeStatics } from "@/services/ri-recommendation/getScopeModalStats.repo";
import { useRiScopeModalTableStats } from "@/services/ri-recommendation/getScopeModalTable.repo";
import ScenariosErrorState from "@/components/States/scenariosErrorState";
import {
  API_LOADING_ERROR,
  API_NO_DATA_FOUND_ERROR,
  API_NO_DATA_FOUND_FOR_FILTERS_ERROR,
  API_NOT_FOUND_ERROR,
} from "@/lib/errors/error-constants";

const ScopeInstanceModal = (service) => {
  const context = useContext(RiContext);
  const [hideFilterSection, setHideFilterSection] = useState(false);

  const handleClose = () => {
    context.setShowScopeModal(false);
  };
  // const { instanceFlexibility, specs, currentScope, recommendations } = data;

  // const maxSavingsPerMonth = Math.max(
  //   ...recommendations.map((model) => parseInt(model.savingsPerMonth.slice(1)))
  // );

  const query = useMemo(() => {
    return {
      service: "azure",
      servicetype: "scope",
      type: context?.tableType,
      reservationId: context?.accountID,
      scopeName: context?.scopeName,
      subscriptionId: context?.subscriptionId,
      rgName: context?.rgName,
    };
  }, [
    context?.accountID,
    context?.rgName,
    context?.scopeName,
    context?.subscriptionId,
    context?.tableType,
  ]);

  const {
    data: modaldata,
    isLoading: dataisLoading,
    error,
  } = useScopeStatics(query);
  const { data: modaltabledata, isLoading: modaltabledataIsLoading } =
    useRiScopeModalTableStats(query);
  useEffect(() => {
    if (
      error &&
      (error.message === API_NO_DATA_FOUND_ERROR ||
        error.message === API_NOT_FOUND_ERROR ||
        error.message === API_LOADING_ERROR)
    ) {
      setHideFilterSection(true);
    } else {
      setHideFilterSection(false);
    }
  }, [error]);

  // const maxSavingsPerMonth = Math.max(
  //   ...(modaltabledata?.recommendations || []).map((model) =>
  //     parseInt(model.savingsPerMonth))
  // );


  return (
    <Offcanvas
      show={context?.showScopeModal}
      placement="end"
      style={{
        backgroundColor: hideFilterSection ? "#F8F9FA" : "",
        width: "90%",
        padding: "16px",
        overflowY: "scroll",
        gap: "12px",
      }}
    >
      <div className={style.recommendationHeader}>
        <span
          style={{
            fontSize: "18px",
            fontWeight: "700",
            display: "flex",
            alignItems: "center",
          }}
        >
          {!hideFilterSection && (
            <>
              Scope & Instance Flexibility Recommendation
              <img
                style={{ marginLeft: "8px" }}
                src={infoIcon}
                width={16}
                height={16}
                alt="infoIcon"
              />
            </>
          )}
        </span>
        <img src={crossModal} alt="Close Notification" onClick={handleClose} />
      </div>

      {dataisLoading && (
        <div
          style={{
            width: "100%",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner />
        </div>
      )}
      {error && (
        <ScenariosErrorState
          error={error.message}
          messageConfig={{
            [API_NO_DATA_FOUND_FOR_FILTERS_ERROR]: {
              message: "No Data Available for Selected Filters",
              additionalMessage:
                "It looks like there are no data matching your filter criteria. Please try adjusting your filters to explore other options.",
            },
            [API_NO_DATA_FOUND_ERROR]: {
              message: "No Data Available",
              additionalMessage:
                "We couldn’t retrieve data at this moment. This might be due to an absence of data or a temporary issue. Please try again later.",
            },
            [API_LOADING_ERROR]: {
              message: "Oops!",
              additionalMessage:
                "We encountered a problem loading your data. Please ensure a stable internet connection and try again. If the issue persists, contact support.",
            },
            [API_NOT_FOUND_ERROR]: {
              message: "404 Error",
              additionalMessage:
                "We couldn’t find the data you’re looking for. This might be due to a temporary issue or incorrect resource. Please check back later or contact support if the problem persists.",
            },
          }}
        />
      )}

      {modaldata?.recommendations?.length > 0 && (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "12px",
            }}
          >
            <div className={style.recommendationText}>
              <p>
                Instance size flexibility will be enabled by default for any
                existing Azure Reserved Instance with shared scope, while for
                reserved instances with single scope you can enable instance
                size flexibility from the reservation configuration. 
              </p>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "16px 15% 16px 12px",
              }}
            >
              {/* {(reservedData?.reservations || []).map((reservation, index) => ( */}
              <div className={modulestyle.VM_family_block}>
                <span className={modulestyle.vm_name}>Reservation Name</span>
                <span>{modaldata?.reservation?.reservationName || "--"}</span>
              </div>
              <div className={modulestyle.VM_family_block}>
                <span className={modulestyle.vm_name}>Purchased Units </span>
                <span>{modaldata?.reservation?.purchasedQuantity || 0}</span>
              </div>
              <div className={modulestyle.VM_family_block}>
                <span className={modulestyle.vm_name}>Term</span>
                <span>{modaldata?.reservation?.term || 0}</span>
              </div>
              <div className={modulestyle.VM_family_block}>
                <span className={modulestyle.vm_name}>Expires on</span>
                <span> {modaldata?.reservation?.expiresOn || 0}</span>
              </div>
              {service.service === "azure" && (
                <div className={modulestyle.VM_family_block}>
                  <span className={modulestyle.vm_name}>
                    Instance Flexibility
                  </span>
                  <span>
                    {modaldata?.reservation?.instanceFlexibility || "--"}
                  </span>
                </div>
              )}
            </div>
          </div>

          <InstanceComponent
            service={service.service}
            data={modaldata}
            dataisLoading={dataisLoading}
            modaltabledataIsLoading={modaltabledataIsLoading}
            // maxSavingsPerMonth={maxSavingsPerMonth}
          />
          <div className={modulestyle.resourceDetails}>
            <span className={modulestyle.resource_name}>VM Series:</span>
            <span className={modulestyle.resource_value}>
              {modaldata?.reservation?.vmFamily || "--"},
            </span>
            <span className={modulestyle.resource_name}>Region:</span>
            <span className={modulestyle.resource_value}>
              {modaldata?.reservation?.region || "--"},
            </span>
            <span className={modulestyle.resource_name}>Platform:</span>
            <span className={modulestyle.resource_value}>
              {modaldata?.reservation?.platform || "--"} ,
            </span>
            <span className={modulestyle.resource_name}>Tenancy:</span>
            <span className={modulestyle.resource_value}>
              {modaldata?.reservation?.tenensy || "--"}
            </span>
          </div>

          {/* <SpecsModal/> */}

          <ScopeModalTable
            modaltabledata={modaltabledata}
            service={service.service}
          />
        </>
      )}
    </Offcanvas>
  );
};

export default ScopeInstanceModal;
