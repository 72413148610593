import React, { useContext } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
  Legend,
} from "recharts";
import awsLogo from "@/assets/img/aws_logo.svg";
import azureLogo from "@/assets/img/azurelogo.svg";
import gcpLogo from "@/assets/img/gcp_logo.svg";
import styles from "./../cost.explorer.module.css";
import { FilterContext } from "./filter-section/filter-section.component";
import CurrencyFormatter from "@/lib/value-converison";

/**
 * @typedef StackedBarGraphProps
 * @property {*} barChartData
 * @property {*} colorMap
 * @property {boolean=} showLegend
 * @property {boolean=} showTooltip
 * @property {number=} height
 */

/**
 * @param {StackedBarGraphProps} props
 */

const StackedBarGraph = ({
  barChartData = [],
  colorMap,
  showLegend,
  showTooltip,
  height = 300,
  service,
  dimensionData,
}) => {
  const interval =
    barChartData.length > 15 ? Math.floor(barChartData.length / 10) : 0;
  const uniqueKeys = Array.isArray(barChartData)
    ? Array.from(
        new Set(
          barChartData.flatMap((data) =>
            Object.keys(data).filter((key) => key !== "label")
          )
        )
      )
    : [];

  return (
    <ResponsiveContainer height={height} width="100%">
      <BarChart
        data={barChartData}
        className={styles.legends_Style}
        margin={{ top: 0, right: 3, left: -13, bottom: 0 }}
        stackOffset="sign"
      >
        <XAxis
          dataKey="label"
          tickLine={false}
          axisLine={false}
          fontSize={10}
          fontWeight={400}
          stroke="#A3A3A3"
          fontFamily="Inter"
          // interval={interval}
          textAnchor="middle"
          tickMargin={7}
        />
        <YAxis
          label={{ fill: "#A3A3A3" }}
          tickLine={false}
          axisLine={false}
          fontSize={10}
          fontWeight={400}
          stroke="#A3A3A3"
          fontFamily="Inter"
        />
        <CartesianGrid strokeDasharray="0" stroke="#F0F0F0" />
        {showTooltip && (
          <Tooltip
            content={
              <CustomTooltip service={service} dimensionData={dimensionData} />
            }
            cursor={{ fill: "transparent" }}
          />
        )}
        {showLegend && <Legend content={<CustomLegend />} />}
        {uniqueKeys.length > 0 &&
          uniqueKeys.map((key) => (
            <Bar
              key={key}
              dataKey={key}
              stackId="a"
              fill={colorMap[key]}
              barSize={80}
            />
          ))}
      </BarChart>
    </ResponsiveContainer>
  );
};

export const CustomTooltip = ({
  active,
  payload,
  label,
  service,
  dimensionData,
}) => {
  const filterContext = useContext(FilterContext);

  const filteredPayload = payload.sort((a, b) => a.value - b.value);

  const dimensionCheck = (dimensionData?.dimensions || []).find(
    (d) => d.id === filterContext.selectedDimension
  );
  const dimensionName = dimensionCheck?.name || "Dimensions";

  return (
    <div className={styles.custom_tooltip}>
      <div className="tooltip_date">
        <div className="tooltip_date_css">{label}</div>
      </div>
      <table>
        <thead>
          <tr>
            <th className={styles.cloud_cell}>
              {filterContext?.selectedDimension.length > 0
                ? dimensionName
                : "All Dimensions"}
            </th>
            <th className={styles.cost_cell}>Costs</th>
          </tr>
        </thead>
        <tbody>
          {filteredPayload.map((entry, index) => (
            <tr key={`tooltip-row-${index}`}>
              <td className={styles.cloud_cell}>
                {entry.dataKey === "AWS" && (
                  <img src={awsLogo} alt="AWS" className="cloud-logo" />
                )}
                {entry.dataKey === "Azure" && (
                  <img src={azureLogo} alt="Azure" className="cloud-logo" />
                )}
                {entry.dataKey === "GCP" && (
                  <img src={gcpLogo} alt="GCP" className="cloud-logo" />
                )}
                {entry.datakey !== "Null" ? entry.dataKey || "NA" : "NA"}
              </td>
              <td className={styles.cost_cell}>
                <CurrencyFormatter value={entry.value} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export const CustomLegend = ({ payload }) => {
  return (
    <div
      style={{ display: "flex", justifyContent: "center", margin: "10px 0" }}
    >
      {payload.map((bar, barIndex) => (
        <div
          key={barIndex}
          style={{
            display: "flex",
            alignItems: "center",
            marginRight: 15,
          }}
        >
          <div
            style={{
              width: 14,
              height: 14,
              backgroundColor: bar.color,
              marginRight: 5,
            }}
          ></div>
          <span
            style={{
              fontSize: 12,
              fontWeight: 400,
              fontFamily: "Inter",
              color: "#000000",
              textWrap: "nowrap",
              textOverflow: "ellipsis",
              maxWidth: "200px",
              overflow: "hidden",
            }}
            title={bar.value !== "Null" ? bar.value || "NA" : "NA"}
          >
            {bar.value !== "Null" ? bar.value || "NA" : "NA"}
          </span>
        </div>
      ))}
    </div>
  );
};

export default StackedBarGraph;
