import React, { useState, useRef, useEffect, useContext } from "react";
import styles from "@/page-components/savings/components/react-calendar/calendar.module.css";
import calender_side_arrow from "@/assets/img/calender_side_arrow.svg";
import { format, subDays, subMonths } from "date-fns";
import PrevYearIcon from "@/assets/img/savingPrevYearIcon.svg";
import NextYearIcon from "@/assets/img/savingNextIcon.svg";
import { IdleContext } from "../idle-context-file.part";

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
const CustomCalendar = ({ range, setRange, setIsDropdownOpen }) => {
  const context = useContext(IdleContext);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedStartMonth, setSelectedStartMonth] = useState(null);
  const [selectedEndMonth, setSelectedEndMonth] = useState(null);
  const [selectedStartYear, setSelectedStartYear] = useState(null);
  const [selectedEndYear, setSelectedEndYear] = useState(null);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
  // const [selectedDate, setSelectedDate] = useState(null);

  const calendarRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const validEndDate = new Date(); // Current month
  const validStartDate = subMonths(validEndDate, 12);

  const handleMonthSelection = (monthIndex) => {
    if (selectedStartMonth === null) {
      setSelectedStartMonth(monthIndex);
      setSelectedStartYear(currentYear);
      setSelectedEndMonth(null);
      setSelectedEndYear(null);
    } else if (
      selectedEndMonth === null &&
      (currentYear > selectedStartYear || monthIndex >= selectedStartMonth)
    ) {
      setSelectedEndMonth(monthIndex);
      setSelectedEndYear(currentYear);
    } else {
      setSelectedStartMonth(monthIndex);
      setSelectedStartYear(currentYear);
      setSelectedEndMonth(null);
      setSelectedEndYear(null);
    }
  };

  const applySelectedMonthYear = () => {
    if (selectedStartMonth !== null) {
      const startDate = new Date(selectedStartYear, selectedStartMonth, 1);
      const endDate =
        selectedEndMonth !== null
          ? new Date(selectedEndYear, selectedEndMonth + 1, 0)
          : new Date(selectedStartYear, selectedStartMonth + 1, 0);

      const selectedRange = {
        startDate,
        endDate,
        key: "selection",
      };

      setRange([selectedRange]);

      const formattedDate =
        selectedEndMonth !== null && selectedEndMonth !== selectedStartMonth
          ? `${months[selectedStartMonth]} ${selectedStartYear} - ${months[selectedEndMonth]} ${selectedEndYear}`
          : `${months[selectedStartMonth]} ${selectedStartYear}`;

      context?.setSelectedDate(formattedDate);
      context?.setSelectMonthRange("custom");
      setIsOpen(false);
      setIsDropdownOpen(false);
    }
  };

  // const handlePrevYear = () => {
  //   setCurrentYear(currentYear - 1);
  //   if (selectedStartYear === currentYear) {
  //     setSelectedStartMonth(null);
  //     setSelectedEndMonth(null);
  //   }
  // };
  const handlePrevYear = () => {
    if (currentYear > validStartDate.getFullYear()) {
      setCurrentYear(currentYear - 1);
      if (selectedStartYear === currentYear) {
        setSelectedStartMonth(null);
        setSelectedEndMonth(null);
      }
    }
  };

  const handleNextYear = () => {
    if (currentYear < new Date().getFullYear()) {
      setCurrentYear(currentYear + 1);
    }
  };

  const monthInRange = (monthIndex, year) => {
    if (selectedStartMonth === null) return false;
    const startMonthYear = new Date(selectedStartYear, selectedStartMonth);
    const endMonthYear =
      selectedEndMonth !== null
        ? new Date(selectedEndYear, selectedEndMonth)
        : null;
    const currentMonthYear = new Date(year, monthIndex);

    return (
      currentMonthYear >= startMonthYear &&
      (endMonthYear
        ? currentMonthYear <= endMonthYear
        : currentMonthYear <= startMonthYear)
    );
  };

  // const renderMonthGrid = () => {
  //   const monthSets = [
  //     [0, 3, 6, 9],
  //     [1, 4, 7, 10],
  //     [2, 5, 8, 11],
  //   ];

  //   return monthSets.map((set, setIndex) => (
  //     <div key={setIndex} className={styles.month_set}>
  //       {set.map((monthIndex, index) => {
  //         const disabled =
  //           currentYear === new Date().getFullYear() &&
  //           monthIndex > currentMonth;
  //         const selected = monthInRange(monthIndex, currentYear);
  //         const isCurrentMonth =
  //           currentYear === new Date().getFullYear() &&
  //           monthIndex === currentMonth;
  //         const redHighlight = !selected && isCurrentMonth;
  //         return (
  //           <div
  //             key={index}
  //             className={`${styles.month_option} ${
  //               selected ? styles.selected : ""
  //             } ${disabled ? styles.disabled : ""} ${
  //               monthIndex === selectedStartMonth &&
  //               currentYear === selectedStartYear
  //                 ? styles.start_month
  //                 : ""
  //             } ${
  //               monthIndex === selectedEndMonth &&
  //               currentYear === selectedEndYear
  //                 ? styles.end_month
  //                 : ""
  //             }`}
  //             onClick={() => handleMonthSelection(monthIndex)}
  //             style={{
  //               pointerEvents: disabled ? "none" : "auto",
  //               color: disabled ? "#495057" : "inherit",
  //               opacity: disabled ? 0.5 : 1,
  //               color: redHighlight ? "#056EE5" : "",
  //               fontWeight: redHighlight ? 600 : " ",
  //             }}
  //           >
  //             {months[monthIndex]}
  //           </div>
  //         );
  //       })}
  //     </div>
  //   ));
  // };

  const renderMonthGrid = () => {
    const monthSets = [
      [0, 3, 6, 9],
      [1, 4, 7, 10],
      [2, 5, 8, 11],
    ];

    return monthSets.map((set, setIndex) => (
      <div key={setIndex} className={styles.month_set}>
        {set.map((monthIndex, index) => {
          const currentMonthYear = new Date(currentYear, monthIndex);
          const disabled =
            currentMonthYear < validStartDate ||
            currentMonthYear > validEndDate;
          const selected = monthInRange(monthIndex, currentYear);
          return (
            <div
              key={index}
              className={`${styles.month_option} ${
                selected ? styles.selected : ""
              } ${disabled ? styles.disabled : ""}`}
              onClick={() => handleMonthSelection(monthIndex)}
              style={{
                pointerEvents: disabled ? "none" : "auto",
                opacity: disabled ? 0.5 : 1,
              }}
            >
              {months[monthIndex]}
            </div>
          );
        })}
      </div>
    ));
  };

  return (
    <div
      className={styles.calendar_button_container}
      ref={calendarRef}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "8px",
      }}
    >
      <div className={styles.calendar_header}>
        <img
          src={PrevYearIcon}
          alt="Prev Year"
          onClick={handlePrevYear}
          style={{
            opacity: currentYear <= validStartDate.getFullYear() ? 0.5 : 1,
            cursor:
              currentYear <= validStartDate.getFullYear() ? "none" : "pointer",
          }}
        />

        <div className={styles.current_year}>{currentYear}</div>
        <img
          src={NextYearIcon}
          style={{
            border: "none",
            opacity: currentYear === new Date().getFullYear() ? 0.2 : 1,
            cursor: currentYear === new Date().getFullYear() ? "" : "pointer",
          }}
          disabled={currentYear === new Date().getFullYear()}
          onClick={handleNextYear}
          alt=""
        ></img>
      </div>
      <div style={{ display: "flex", justifyContent: "flex-start" }}>
        {renderMonthGrid()}
      </div>
      <button onClick={applySelectedMonthYear} className={styles.apply_button}>
        Apply
      </button>
    </div>
  );
};

export default CustomCalendar;
