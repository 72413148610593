import React from "react";
import {
  Line,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ComposedChart,
  ResponsiveContainer,
} from "recharts";
import styles from "@/page-components/idle-resource/idle.module.css";
import tooltipStyles from "@/page-components/idle-resource/idle.module.css";
import CurrencyFormatter from "@/lib/value-converison";

const IdleHistoryBarChart = ({ data }) => {
  return (
    <ResponsiveContainer width="100%" height={300}>
      <BarChart
        data={data}
        className={styles.legend_custom_color}
        margin={{ top: 0, right: 3, left: -13, bottom: 0 }}
        stackOffset="sign"
      >
        <XAxis
          dataKey="month"
          tickLine={false}
          axisLine={false}
          fontSize={10}
          fontWeight={500}
          stroke="var(--Neutral-D3, #495057)"
        />
        <YAxis
          label={{ fill: "#A3A3A3" }}
          tickLine={false}
          axisLine={false}
          fontSize={10}
          fontWeight={400}
          stroke="var(--Neutral-D3, #495057)"
        />
        <CartesianGrid strokeDasharray="0" stroke="#F0F0F0" />
        <Tooltip content={<CustomTooltip />} cursor={{ fill: "transparent" }} />
        <Legend
          align="center"
          verticalAlign="bottom"
          layout="horizontal"
          iconSize={12}
          iconType="Square"
          wrapperStyle={{}}
          fontSize={12}
          fontWeight={400}
          fontStyle="normal"
        />
        <Bar
          dataKey="deleted"
          fill="#056EE5"
          name="Idle Resources Deleted"
          barSize={100}
        />
        <Bar
          dataKey="added"
          fill="#E8BF67"
          name="Idle Resources Added"
          barSize={100}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default IdleHistoryBarChart;

export const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    const deletedEntry = payload.find((entry) => entry.dataKey === "deleted");
    const addedEntry = payload.find((entry) => entry.dataKey === "added");

    const savingValue = payload[0]?.payload?.deleted_savings || 0;

    const deletedValue = deletedEntry?.value || 0;
    const addedValue = addedEntry?.value || 0;
    const deletedColor = deletedEntry?.color || "#056EE5";
    const addedColor = addedEntry?.color || "#E8BF67";

    return (
      <div className={tooltipStyles.custom_tooltip} style={{ width: "275px" }}>
        <div
          className={tooltipStyles.tooltip_date}
          style={{ paddingLeft: "8px" }}
        >
          <div className={tooltipStyles.tooltip_date_css}>{label}</div>
        </div>

        <table style={{ width: "92%", margin: "0px 12px 4px 12px" }}>
          <tbody style={{ width: "100%" }}>
            <tr className={styles.tooltip_row}>
              <div className={styles.tooltip_table_flex}>
                <div className={styles.display_icon}>
                  <div
                    style={{
                      width: "12px",
                      height: "12px",
                      backgroundColor: deletedColor,
                      borderRadius: "2px",
                    }}
                  ></div>
                  <span className={styles.tooltip_cloud_name}>
                    Idle Resources Deleted
                  </span>
                </div>
                <span className={styles.tooltip_tr_value}>
                  {deletedValue}
                </span>
              </div>
            </tr>
            <tr className={styles.tooltip_row}>
              <div className={styles.tooltip_table_flex}>
                <div className={styles.display_icon}>
                  <div
                    style={{
                      width: "12px",
                      height: "12px",
                      backgroundColor: addedColor,
                      borderRadius: "2px",
                    }}
                  ></div>
                  <span className={styles.tooltip_cloud_name}>
                    Idle Resources Added
                  </span>
                </div>
                <span className={styles.tooltip_tr_value}>
                  {addedValue} 
                </span>
              </div>
            </tr>
            <tr className={styles.tooltip_row}>
              <div className={styles.tooltip_table_flex}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <span
                    className={styles.tooltip_cloud_name}
                    style={{ fontSize: "12px" }}
                  >
                    Savings
                  </span>
                  <span className={styles.deleted_reource}>
                    (Deleted Idle Resource)
                  </span>
                </div>
                <span className={styles.tooltip_tr_value}>
                  <CurrencyFormatter value={savingValue} />
                </span>
              </div>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
  return null;
};
