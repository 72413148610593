import { useContext } from "react";
import config from "../../config";
import { useQuery } from "@tanstack/react-query";
import { AppContext } from "@/App.context";


// {
//   "type": "serviceCategory",
//   "title": "Storage",
//   "averageDailyCost": 0.0005076977419729019,
//   "percentCostChange": 100,
//   "totalCost": 20.571404807000004,
//   "potentialSavings": 0.13960000000000003
// }

/**
 * @typedef GetAllStatistics
 * @property {string} type
 * @property {string} title
 * @property {number} averageDailyCost
 * @property {number} percentCostChange
 * @property {number} totalCost
 * @property {number} potentialSavings
 */

/**
 * @typedef GetAllStatisticsRes
 * @property {GetAllStatistics} data
 */

/**
 * @typedef GetAllStatisticsReq
 * @property {'aws' | 'gcp' | 'azure' | 'allclouds' } service
 * @property {string} fromDate
 * @property {string} toDate
 * @property {string} type
 * @property {string} name
 * @property {string} accounts
 * @property {string} regions
 */

export const getAllAccountRegion = (token) => {
  /**
   * @param {GetAllStatisticsReq} req
   * @param {AbortSignal=} signal
   * @returns {Promise<GetAllStatisticsRes>}
   */
  return async (
    { service, fromDate, toDate, accounts, regions, type, name,spendingBy, serviceName },
    signal
  ) => {
    const body = {
      accounts: accounts || [],
      regions: regions || [],
      fromDate: fromDate,
      toDate: toDate,
      type: type,
      name: name,
      spendingBy:spendingBy,
      service: type === "resource" ? serviceName : undefined,
    };
    const statistics = await fetch(
      `${config.apiHost}/v1/${service}/search/search-result/filters`,
      {
        method: "POST",
        body: JSON.stringify(body),
        signal,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    if (!statistics.ok) {
      throw new Error((await statistics.json()).message);
    }
    const response = await statistics.json();
    return response;
  };
};

/**
 * @param {GetAllStatisticsReq} req
 * @param {any[]=} deps
 */
export const useSearchAccountRegion = (req, deps = []) => {
  const appContext = useContext(AppContext);
  const query = useQuery({
    queryKey: ["getAllAccountRegion", req, ...deps],
    queryFn: ({ signal }) => getAllAccountRegion(appContext.idToken)(req, signal),
  });
  return query;
};
