// ----------------------------------------------IN NO USE-------------------------
import { AppContext } from "@/App.context";
import config from "@/config";
import {
  API_LOADING_ERROR_CUSTOM_WIDGET,
  API_NOT_FOUND_ERROR_CUSTOM_WIDGET,
  ERROR_STATUS_CODES,
} from "@/lib/errors/error-constants";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";

/**
 * @typedef AllBodyStatistics
 * @property {Number} deleted
 * @property {Number} added
 * @property {Number} saved_on_deleted
 */
/**
 * @typedef AllBodyStatisticsRes
 * @property {AllBodyStatistics} statistics
 */
/**
 * @typedef GetStatisticsReq
 * @property {'aws' | 'azure' } service
 */

export const getStatisticsHistory = (token) => {
  /**
   * @param {GetStatisticsReq} req
   * @returns {Promise<AllBodyStatisticsRes>}
   */
  return async ({ service,...data}) => {
    const owners = await fetch(
      `${config.apiHost}/v1/${service}/snapshots/history-stats`,
      {
        method: "Post",
        body: JSON.stringify({
          accounts: data?.accounts || [],
          regions: data?.regions || [],
          diskIds: data?.diskIds || [],
          period: data?.period,
          start_date: data?.fromDate,
          end_date: data?.toDate,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        
      }
    );
    if (!owners.ok) {
      const errorResponse = await owners.json();
      if (ERROR_STATUS_CODES.includes(owners.status)) {
        throw new Error(API_LOADING_ERROR_CUSTOM_WIDGET);
      }
      if (owners?.status === 404) {
        throw new Error(API_NOT_FOUND_ERROR_CUSTOM_WIDGET);
      }

      throw new Error(errorResponse.message);
    }
    const res = await owners.json();
    return res;
  };
};

/**
 * @param {GetStatisticsReq} req
 * @param {any[]=} deps
 */
export const useStatisticsHistory = (req, deps = []) => {
  const appContext = useContext(AppContext);
  const query = useQuery({
    queryKey: ["getStatistics", req, ...deps],
    queryFn: () => getStatisticsHistory(appContext.idToken)(req),
  });
  return query;
};
