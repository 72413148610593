/** @type {import('./global')} */
import React, { createContext, useState, useEffect } from "react";
import { differenceInDays, differenceInMonths, format, parse } from "date-fns";

export const snapshotsTabsTypes = {
  Open: "Open",
  Discarded: "Discarded",
};

export const statusCode = {
  Open: "Open",
  [`In Progress`]: "In Progress",
  [`Pending Approval`]: "Pending Approval",
};

/**
 * @typedef SnapshotContextType
 * @property {string[]} selectedAccounts
 * @property {React.Dispatch<React.SetStateAction<string[]>>} setSelectedAccounts
 * @property {string[]} selectedRegions
 * @property {React.Dispatch<React.SetStateAction<string[]>>} setSelectedRegions
 * @property {string[]} selectedDisk
 * @property {React.Dispatch<React.SetStateAction<string[]>>} setSelectedDisk
 * @property {string[]} selectedSnapshotAge
 * @property {React.Dispatch<React.SetStateAction<string[]>>} setSelectedSnapshotAge
 * @property {string[]} selectedSnapshotTags
 * @property {React.Dispatch<React.SetStateAction<string[]>>} setSelectedSnapshotTags
 * @property {string[]} selectedResourceGroup
 * @property {React.Dispatch<React.SetStateAction<string[]>>} setSelectedResourceGroup
 * @property {string[]} snapshotTabs
 * @property {React.Dispatch<React.SetStateAction<string[]>>} setSnapshortTabs
 * @property {string[]} legallyHeldSnapshot
 * @property {React.Dispatch<React.SetStateAction<string[]>>} setLegallyHeldSnapshot
 * @property {string[]} downloadName
 * @property {React.Dispatch<React.SetStateAction<string[]>>} setDownloadName
 */

/**
 * @type {React.Context<SnapshotContextType>}
 */
export const SnapshotContext = createContext();

/**
 * @returns {SnapshotContextType}
 */
export const useSnapshotContext = () => {
  /** @type {UseState<string[]>} */
  const [selectedAccounts, setSelectedAccounts] = useState([]);

  /** @type {UseState<string[]>} */
  const [selectedRegions, setSelectedRegions] = useState([]);

  /** @type {UseState<string[]>} */
  const [selectedDisk, setSelectedDisk] = useState([]);

  /** @type {UseState<string[]>} */
  const [selectedSnapshotAge, setSelectedSnapshotAge] = useState([]);

  /** @type {UseState<string[]>} */
  const [selectedResourceGroup, setSelectedResourceGroup] = useState([]);

  /** @type {UseState<string[]>} */
  const [showUntaggedOnly, setShowUntaggedOnly] = useState(false);

  /** @type {UseState<string[]>} */
  const [showOrphanedOnly, setShowOrphanedOnly] = useState(false);

  /** @type {UseState<string[]>} */
  const [legallyHeldSnapshot, setLegallyHeldSnapshot] = useState(false);

  /** @type {UseState<string[]>} */
  const [downloadName, setDownloadName] = useState([]);

  /** @type {UseState<string[]>} */
  const [selectedItems, setSelectedItems] = useState([]);

  /** @type {UseState<string[]>} */
  const [showJiraModal, setShowJiraModal] = useState(false);

  /** @type {UseState<string[]>} */
  const [acceptViaJira, setAcceptViaJira] = useState(false);

  /** @type {UseState<string[]>} */
  const [showRetainModal, setShowRetainModal] = useState(false);
  /** @type {UseState<string[]>} */
  const [selectedDate, setSelectedDate] = useState(null);

  /** @type {UseState<string[]>} */
  const [selectMonthRange, setSelectMonthRange] = useState("3month");
  /** @type {UseState<string[]>} */
  const [dropdownView, setDropdownView] = useState("main");

  /** @type {UseState<string[]>} */
  const [selectedSnapshotTags, setSelectedSnapshotTags] = useState([
    {
      filterOn: "",
      filterType: "include",
      values: [],
    },
  ]);
  /** @type {UseState<string[]>} */
  const [range, setRange] = useState([
    {
      startDate: "",
      endDate: "",
      key: "selection",
    },
  ]);
  let fromDate = range[0].startDate
    ? format(range[0].startDate, "yyyy-MM-dd")
    : "";
  let toDate = range[0].endDate ? format(range[0].endDate, "yyyy-MM-dd") : "";

  const date2 = parse(toDate, "yyyy-MM-dd", new Date());
  const date1 = parse(fromDate, "yyyy-MM-dd", new Date());
  const daysDifference = differenceInDays(date2, date1) + 1;
  const monthDifference = differenceInMonths(date2, date1) + 1;

  const [value, setValue] = useState([7, 1000]);

  const [eventkey, setEventkey] = useState("Active");
  const [selectedJiraticket, setselectedJiraticket] = useState("");
  const [selectedResourceType, setSelectedResourceType] = useState("Active");
  const [isOpenCalendar, setIsOpenCalendar] = useState(true);
  const [dateRanges, setdateRanges] = useState("3month");

  useEffect(() => {
    setSelectedResourceGroup([]);
  }, [selectedAccounts]);

  return {
    fromDate,
    toDate,
    daysDifference,
    monthDifference,
    range,
    setRange,
    dropdownView,
    setDropdownView,
    selectedDate,
    setSelectedDate,
    selectMonthRange,
    setSelectMonthRange,
    dateRanges,
    setdateRanges,
    isOpenCalendar,
    setIsOpenCalendar,
    selectedResourceType,
    setSelectedResourceType,
    selectedJiraticket,
    setselectedJiraticket,
    showRetainModal,
    setShowRetainModal,
    showJiraModal,
    setShowJiraModal,
    acceptViaJira,
    setAcceptViaJira,
    selectedItems,
    setSelectedItems,
    value,
    setValue,
    eventkey,
    setEventkey,
    selectedAccounts,
    snapshotsTabsTypes,
    setSelectedAccounts,
    selectedRegions,
    setSelectedRegions,
    selectedDisk,
    setSelectedDisk,
    selectedSnapshotAge,
    setSelectedSnapshotAge,
    selectedSnapshotTags,
    setSelectedSnapshotTags,
    selectedResourceGroup,
    setSelectedResourceGroup,
    showUntaggedOnly,
    setShowUntaggedOnly,
    showOrphanedOnly,
    setShowOrphanedOnly,
    legallyHeldSnapshot,
    setLegallyHeldSnapshot,
    downloadName,
    setDownloadName,
  };
};

export const SnapshotProvider = ({ children }) => {
  const context = useSnapshotContext();
  return (
    <>
      <SnapshotContext.Provider value={context}>
        {children}
      </SnapshotContext.Provider>
    </>
  );
};
