import React, { useContext } from "react";
import styles from "@/page-components/billing-credit/billing-credit.module.css";
import { BillingContext, BillingProvider } from "../billing-context.part";
import AzureHeaderComponent from "../components/azure-header-component";
import AllCloudBarChart from "../components/all-cloud-bar-graph.part";
import AllCloudBillingDetails from "../components/all-cloud-collapse-table.part";

const AllCloudBillingPage = () => {
  return (
    <BillingProvider>
      <AllCloudBillingPart />
    </BillingProvider>
  );
};

const AllCloudBillingPart = () => {
  const context = useContext(BillingContext);
  const service = "all-cloud";

  return (
    <div className={styles.billing_comp}>
      <AzureHeaderComponent service={service} />
      <>
        <div
          className={styles.bar_card}
          style={{ marginTop: "24px", paddingRight: "40px" }}
        >
          <AllCloudBarChart service={service}/>
        </div>

        <AllCloudBillingDetails service={service}/>
      </>
    </div>
  );
};

export default AllCloudBillingPage;
