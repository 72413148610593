import { AppContext } from "@/App.context";
import config from "@/config";
import {
  API_LOADING_ERROR,
  API_NO_DATA_FOUND_ERROR,
  API_NO_DATA_FOUND_FOR_FILTERS_ERROR,
  API_NOT_FOUND_ERROR,
  ERROR_STATUS_CODES,
} from "@/lib/errors/error-constants";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";

/**
 * @typedef AllBodyStatistics
 * @property {String} accountname
 * @property {String} instance_type
 * @property {String} region
 * @property {String} tenancy
 * @property {String} platform
 * @property {Number} ondemand_hrs
 * @property {Number} ondemand_cost
 * @property {Number} yearly_ri_cost
 */

/**
 * @typedef AllBodyStatisticsRes
 * @property {AllBodyStatistics} statistics
 */
/**
 * @typedef GetStatisticsReq
 * @property {'aws' | 'azure' | 'gcp' | 'all-cloud' } service
 * @property {'reserved-instance' | 'bulk' | 'scope'  } type
 */

export const getStatistics = (token) => {
  // /**
  //  * @param {GetStatisticsReq} req
  //  * @returns {Promise<AllBodyStatisticsRes>}
  //  */
  return async ({ service, type, page, recordsPerPage, filters }) => {
    const owners = await fetch(
      `${config.apiHost}/v1/${service}/ri-recommendations/${type}/list?page=${page}&recordsPerPage=${recordsPerPage}`,
      {
        method: "POST",
        body: JSON.stringify({
          ...filters,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    if (!owners.ok) {
      if (ERROR_STATUS_CODES.includes(owners.status)) {
        throw new Error(API_LOADING_ERROR);
      }
      if (owners.status === 404) {
        throw new Error(API_NOT_FOUND_ERROR);
      }
      throw new Error((await owners.json()).message);
    }
    const res = await owners.json();
    if (res?.count === 0 || res?.data?.count === 0) {
      if (
        filters?.accounts?.length ||
        filters?.region?.length ||
        filters?.instance?.length ||
        filters?.platform?.length ||
        filters?.vmFamily?.length ||
        filters?.reservation?.length
      ) {
        throw new Error(API_NO_DATA_FOUND_FOR_FILTERS_ERROR);
      }
      throw new Error(API_NO_DATA_FOUND_ERROR);
    }
    
    return res;
  };
};
/**
 * @param {GetStatisticsReq} req
 * @param {any[]=} deps
 */
export const useRiStatistics = (req, deps = []) => {
  const appContext = useContext(AppContext);
  const query = useQuery({
    queryKey: ["getStatistics", req, ...deps],
    queryFn: () => getStatistics(appContext.idToken)(req),
  });
  return query;
};
