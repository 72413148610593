import React from "react";
import { Tooltip } from "react-bootstrap";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  XAxis,
  YAxis,
  ResponsiveContainer,
} from "recharts";
import styles from "@/page-components/search-module/search.module.css";
import { SpendTable } from "@/page-components/search-module/search-components/table-component.part";

const GraphTableSection = ({ data1, service }) => {
  const spendingsData = data1?.spendings || [];

  spendingsData.sort((a, b) => b.cost - a.cost);

  const topSpendings = spendingsData.slice(0, 10);
  return (
    <>
      <ResponsiveContainer height={500} width={600}>
        <BarChart
          data={topSpendings}
          layout="vertical"
          margin={{
            top: 0,
            right: 0,
            left: 50,
          }}
        >
          <CartesianGrid strokeDasharray="0" stroke="#F0F0F0" />
          <XAxis
            type="number"
            className={styles.graphX_axis}
            tickLine={false}
            axisLine={false}
            fontSize={10}
            fontWeight={400}
            stroke="#A3A3A3"
          />
          <YAxis
            type="category"
            className={styles.graphY_axis}
            dataKey="title"
            tickLine={false}
            axisLine={false}
            fontSize={10}
            fontWeight={400}
            stroke="#495057"
            interval={0}
            width={110}
            tick={({ payload, x, y, ...props }) => {
              const displayText = payload.value.length > 15 ? `${payload.value.slice(0, 15)}...` : payload.value; 
              return (
                <text x={x} y={y} {...props} title={payload.value} >
                  <title>{payload.value}</title> 
                  {displayText}
                </text>
              );
            }}
          />
          <CartesianGrid strokeDasharray="0" stroke="#F0F0F0" />
          <XAxis
            type="number"
            className={styles.graphX_axis}
            tickLine={false}
            axisLine={false}
            fontSize={10}
            fontWeight={400}
            stroke="#A3A3A3"
          />
          <YAxis
            type="category"
            className={styles.graphY_axis}
            dataKey="title"
            tickLine={false}
            axisLine={false}
            fontSize={10}
            fontWeight={400}
            stroke="#495057"
          />
          <Tooltip />
          <Bar dataKey="cost" barSize={80}>
            {spendingsData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill="#6EA57C" />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
      <div style={{ width: "39%" }}>
        <SpendTable data1={data1} service={service} />
      </div>
    </>
  );
};

export default GraphTableSection;
