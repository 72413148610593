import React, { useRef, useMemo } from "react";
import "./bargraph.css";
import {
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Bar,
  ResponsiveContainer,
} from "recharts";
import { useAllIndividualStatistics } from "@/services/costExplorer/getAllCloudIndividual.repo";
import { Tooltip } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { ReactComponent as Storage_icon } from "@/assets/img/storage-Icon.svg";
import { ReactComponent as Network_icon } from "@/assets/img/Network-Icon.svg";
import { ReactComponent as Database_icon } from "@/assets/img/database.svg";
import { ReactComponent as Dot_icon } from "@/assets/img/three-dot-icon.svg";
import { ReactComponent as CPU_ICON } from "@/assets/img/CPU-icon.svg";
import styleSheet from "@/page-components/cost-explorer/cost.explorer.module.css";
import ScenariosErrorState from "@/components/States/scenariosErrorState";
import {
  API_LOADING_ERROR,
  API_NO_DATA_FOUND_ERROR,
  API_NO_DATA_FOUND_FOR_DATE_RANGE_ERROR,
  API_NOT_FOUND_ERROR,
} from "@/lib/errors/error-constants";
import VerticalBarGraph from "@/components/States/verticalBarErrorState";
import clsx from "clsx";
import CurrencyFormatter from "@/lib/value-converison";

const IndividualCloudCost = ({ toDate, fromDate, daysDifference }) => {
  const query = useMemo(() => {
    return {
      fromDate,
      toDate,
    };
  }, [fromDate, toDate]);

  const { data, error, isLoading } = useAllIndividualStatistics(query);

  const awsData = [
    {
      name: "Compute",
      uv: Number(data?.awsCostStatics?.Compute) || 0,
      fill: "#E7C067",
      Image: () => <CPU_ICON className={styleSheet.cpuIcon} />,
    },
    {
      name: "Network",
      uv: Number(data?.awsCostStatics?.Network) || 0,
      fill: "#DF8367",
      Image: () => <Network_icon className={styleSheet.cpuIcon} />,
    },
    {
      name: "Storage",
      uv: Number(data?.awsCostStatics?.Storage) || 0,
      fill: "#7FBAFF",
      Image: () => <Storage_icon className={styleSheet.cpuIcon} />,
    },
    {
      name: "Databases",
      uv: Number(data?.awsCostStatics?.Databases) || 0,
      fill: "#9D626E",
      Image: () => <Database_icon className={styleSheet.cpuIcon} />,
    },
    // {
    //   name: "Containers",
    //   uv: Number(data?.awsCostStatics?.Container) || 0,
    //   fill: "#6EA57C",
    //   Image: () => <CONT_Icon className={styleSheet.cpuIcon} />,
    // },
    {
      name: "Others",
      uv: Number(data?.awsCostStatics?.Other) || 0,
      fill: "#CED4DA",
      Image: () => <Dot_icon className={styleSheet.cpuIcon} />,
    },
  ];

  const azureData = [
    {
      name: "Compute",
      uv: Number(data?.azureCostStatics?.Compute) || 0,
      fill: "#E7C067",
      Image: () => <CPU_ICON className={styleSheet.cpuIcon} />,
    },
    {
      name: "Network",
      uv: Number(data?.azureCostStatics?.Network) || 0,
      fill: "#DF8367",
      Image: () => <Network_icon className={styleSheet.cpuIcon} />,
    },
    {
      name: "Storage",
      uv: Number(data?.azureCostStatics?.Storage) || 0,
      fill: "#7FBAFF",
      Image: () => <Storage_icon className={styleSheet.cpuIcon} />,
    },
    {
      name: "Databases",
      uv: Number(data?.azureCostStatics?.Databases) || 0,
      fill: "#9D626E",
      Image: () => <Database_icon className={styleSheet.cpuIcon} />,
    },
    // {
    //   name: "Containers",
    //   uv: Number(data?.azureCostStatics?.Container) || 0,
    //   fill: "#6EA57C",
    //   Image: () => <CPU_ICON className={styleSheet.cpuIcon} />,
    // },
    {
      name: "Others",
      uv: Number(data?.azureCostStatics?.Other) || 0,
      fill: "#CED4DA",
      Image: () => <Dot_icon className={styleSheet.cpuIcon} />,
    },
  ];

  const chartRef = useRef(null);
  const days = daysDifference > 1 ? "days" : "day";
  return (
    <>
      {error && (
        <ScenariosErrorState
          error={error.message}
          messageConfig={{
            [API_NO_DATA_FOUND_FOR_DATE_RANGE_ERROR]: {
              message: "No Data for the Selected Date Range",
              additionalMessage:
                "The selected date range doesn’t contain any savings data. Try choosing a different range to view available savings.",
            },
            [API_LOADING_ERROR]: {
              message: "Oops!",
              additionalMessage:
                "We encountered a problem loading your data. Please ensure a stable internet connection and try again. If the issue persists, contact support.",
            },
            [API_NOT_FOUND_ERROR]: {
              message: "404 Error",
              additionalMessage:
                "We couldn’t find the data you’re looking for. This might be due to a temporary issue or incorrect resource. Please check back later or contact support if the problem persists.",
            },
            [API_NO_DATA_FOUND_ERROR]: {
              message: "No Data Available",
              additionalMessage:
                "We couldn’t retrieve data at this moment. This might be due to an absence of data or a temporary issue. Please try again later.",
            },
          }}
        />
      )}
      {!error && !data && isLoading && (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            gap: "16px",
            height: "30vh",
          }}
        >
          <VerticalBarGraph />
          <VerticalBarGraph />
        </div>
      )}
      {!error && !isLoading && data && (
        <div
          className={clsx("container-fluid")}
          style={{ paddingBottom: "32px" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "start",
              gap: "8px",
              alignItems: "start",
              marginBottom: "16px",
            }}
          >
            <div className="all_cloud_text1">Individual Cloud Costs</div>
            <div className="last_days_name1">{daysDifference}</div>
          </div>
          <div className="graph_main_div row">
            {/* AWS Costs */}
            <div className="col-sm-6 col-md-4  cost_graph">
              <div className="d-flex justify-content-start align-items-center w-100">
                <div className="aws_costs_div">
                  <div className="aws_cost_name">AWS costs</div>
                  <div className="aws_cost_value1">
                    <CurrencyFormatter
                      value={data?.awsCostStatics?.totalCost}
                    />
                  </div>
                </div>
              </div>
              <div className="row w-100">
                <div className="col-sm-12 p-0">
                  <div className="vertical-bar-chart" ref={chartRef}>
                    <ResponsiveContainer width="100%" height={195}>
                      <BarChart
                        data={awsData}
                        layout="vertical"
                        margin={{ top: 0, right: 0, bottom: 10, left: 0 }}
                      >
                        <XAxis
                          type="number"
                          axisLine={false}
                          tickLine={false}
                          stroke="#A3A3A3"
                          fontSize={10}
                          fontWeight={400}
                          fontFamily="Helvetica"
                        />

                        <YAxis
                          dataKey="name"
                          type="category"
                          axisLine={false}
                          tickLine={false}
                          width={100}
                          fontSize={10}
                          fontWeight={400}
                          fontFamily="Helvetica"
                        />

                        <CartesianGrid strokeDasharray="0" stroke="#F0F0F0" />
                        <Bar
                          dataKey="uv"
                          fill="#CF9FFF"
                          shape={(props) => (
                            <CustomBar
                              {...props}
                              Image={props.payload.Image}
                              // serviceType={serviceType}
                              // type={props.payload.type}
                            />
                          )}
                        />
                      </BarChart>
                    </ResponsiveContainer>
                  </div>
                </div>
              </div>
            </div>

            {/* Azure Costs */}
            <div className="col-sm-6 col-md-4  cost_graph">
              <div className="d-flex justify-content-start align-items-center w-100">
                <div className="aws_costs_div">
                  <div className="aws_cost_name">Azure costs</div>
                  <div className="aws_cost_value1">
                    <CurrencyFormatter
                      value={data?.azureCostStatics?.totalCost}
                    />
                  </div>
                </div>
              </div>
              <div className="row w-100">
                <div className="col-sm-12 p-0">
                  <div className="vertical-bar-chart" ref={chartRef}>
                    <ResponsiveContainer width="100%" height={195}>
                      <BarChart
                        data={azureData}
                        layout="vertical"
                        margin={{ top: 0, right: 0, bottom: 10, left: 0 }}
                      >
                        <XAxis
                          type="number"
                          axisLine={false}
                          tickLine={false}
                          stroke="#A3A3A3"
                          fontSize={10}
                          fontWeight={400}
                          fontFamily="Inter"
                        />

                        <YAxis
                          dataKey="name"
                          type="category"
                          axisLine={false}
                          tickLine={false}
                          width={100}
                          fontSize={10}
                          fontWeight={400}
                          fontFamily="Inter"
                        />
                        <CartesianGrid strokeDasharray="0" stroke="#F0F0F0" />
                        <Bar
                          dataKey="uv"
                          fill="#CF9FFF"
                          shape={(props) => (
                            <CustomBar
                              {...props}
                              Image={props.payload.Image}
                              // serviceType={serviceType}
                              // type={props.payload.type}
                            />
                          )}
                        />
                      </BarChart>
                    </ResponsiveContainer>
                  </div>
                </div>
              </div>
            </div>

            {/* GCP Costs */}
            {/* <div className="col-sm-6 col-md-4  cost_graph">
          <div className="d-flex justify-content-start align-items-center w-100">
            <div className="aws_costs_div">
              <div className="aws_cost_name">GCP costs</div>
              <div className="aws_cost_value1">$3,084</div>
            </div>
          </div>
          <div className="row w-100">
            <div className="col-sm-12 p-0">
              <div className="vertical-bar-chart" ref={chartRef}>
                <ResponsiveContainer width="100%" height={195}>
                  <BarChart
                    data={awsGraphData}
                    layout="vertical"
                    margin={{ top: 0, right: 0, bottom: 10, left: 0 }}
                  >
                    <XAxis
                      type="number"
                      axisLine={false}
                      tickLine={false}
                      stroke="#A3A3A3"
                      fontSize={10}
                      fontWeight={400}
                      fontFamily="Helvetica"
                    />

                    <YAxis
                      dataKey="name"
                      type="category"
                      axisLine={false}
                      tickLine={false}
                      width={100}
                      fontSize={10}
                      fontWeight={400}
                      fontFamily="Helvetica"
                    />
                    <CartesianGrid strokeDasharray="0" stroke="#F0F0F0" />
                    <Bar dataKey="uv" fill="#CF9FFF" />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>
          </div>
        </div> */}
          </div>
        </div>
      )}
    </>
  );
};

const CustomBar = ({
  x,
  y,
  width,
  height,
  value,

  fill,
  name,
  Image,
  type,
}) => {
  return (
    <OverlayTrigger
      placement="top"
      overlay={
        <Tooltip
          id={`tooltip_${value}`}
          style={{
            backgroundColor: "black",
            margin: "0px",
            padding: "0px",
            position: "absolute",
            borderRadius: "8px",
            width: "161px",
          }}
        >
          <div
            style={{
              marginLeft: "-8px",
              marginRight: "-8px",
              marginTop: "-4px",
              borderRadius: "6px 6px 0px 0px",
            }}
          >
            <div
              className={styleSheet.tooltip_css_boot}
              style={{
                borderRadius: "6px 6px 0px 0px",
              }}
            >
              <span className={styleSheet.tooltip_name}>
                <span style={{ marginRight: "8px" }}>
                  <Image />
                </span>
                {name}
              </span>
            </div>

            <table style={{ width: "100%" }}>
              <tbody>
                <tr>
                  <td
                    style={{
                      textAlign: "left",
                      padding: "0px 12px 0px 12px",
                      width: "60%",
                      fontWeight: "400",
                      fontSize: "12px",
                      color: "#ADB5BD",
                      lineHeight: "14px",
                    }}
                  >
                    Cost
                  </td>
                  <strong
                    style={{
                      textAlign: "right",
                      fontWeight: "500",
                      fontSize: "12px",
                      color: "#F8F9FA",
                      lineHeight: "14px",
                    }}
                  >
                    <CurrencyFormatter value={value} />
                  </strong>
                </tr>
              </tbody>
            </table>
          </div>
        </Tooltip>
      }
    >
      <rect x={x} y={y} width={width} height={height} fill={fill} />
    </OverlayTrigger>
  );
};

export default IndividualCloudCost;
