import React, { useState } from "react";
import styles from "@/components/state-change/state.module.css";
import cloudService from "@/page-components/cost-explorer/components/usage-details/cloud.service";
import clsx from "clsx";
import { ToolTipComponent } from "@/page-components/cost-explorer/components/cost-explorer-header.component";
import CurrencyFormatter from "@/lib/value-converison";

const ViewDiscreptionTable = ({
  selectedRows,
  onRowsChange,
  jira_status,
  table_account,
  description,
  selectedResourceId,
}) => {
  const statusMapping = {
    active: "Active",
    in_progress: "In Progress",
    deleted: "Deleted",
  };

  return (
    <div
      style={{
        width: "100%",
        // background: "#F8F9FA",
        display: "flex",
        flexDirection: "column",
        gap: "24px",
        paddingTop: "20px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          gap: "4px",
        }}
      >
        <span className={styles.selected_resource_des}>Description</span>
        <span className={styles.description_css}>{description || "--"}</span>
      </div>
      <div
        style={{ maxHeight: "200px", overflowY: "auto", position: "relative" }}
      >
        <table
          className="table"
          id={styles.customers}
          style={{ width: "100%" }}
        >
          <thead>
            <tr
              style={{
                position: "sticky",
                top: 0,
                backgroundColor: "#fff",
                zIndex: 1,
              }}
            >
              <th className={clsx(styles.th_class, styles.table_td_width)}>
                {table_account}
              </th>
              <th className={clsx(styles.th_class, styles.table_td_width)}>
                Resource Name
              </th>
              <th className={clsx(styles.th_class, styles.table_td_width)}>
                Resource Type
              </th>
              <th
                className={clsx(styles.th_class, styles.table_td_width)}
                style={{ textAlign: "right" }}
              >
                <span>Projected Savings</span>
              </th>
              <th className={clsx(styles.th_class, styles.table_td_width)}>
                Status
              </th>
              <th className={clsx(styles.th_class, styles.table_td_width)}>
                Jira Status
              </th>
            </tr>
          </thead>
          <tbody>
            {(selectedRows || []).map((item, index) => (
              <tr
                key={index}
                className={clsx(styles.table_row, "p-0", {
                  [styles.highlightedRow]: item?.resource_id === selectedResourceId,
                })}
              >
                <td
                  className={styles.table_data_des}
                  title={item?.account_name}
                >
                  {item?.account_name || "--"}
                </td>
                <td
                  className={styles.table_data_des}
                  title={item?.idle_resource_name || item?.name}
                >
                  {item?.idle_resource_name || item?.name || "--"}
                </td>
                <td className={styles.table_data_des}>
                  {item?.resource_type || "--"}
                </td>
                <td
                  className={styles.table_data_des}
                  style={{
                    color: "var(--Success-Text, #127E24)",
                    fontWeight: 600,
                    fontSize: 13,
                    paddingRight: "8px",
                    textAlign: "right",
                  }}
                >
                  <ToolTipComponent data={item?.idle_resource_cost || 0}>
                    {item?.idle_resource_cost < 0.1 && "< $0.1"}
                    {item?.idle_resource_cost > 0.1 && (
                      <CurrencyFormatter
                        value={item?.idle_resource_cost || 0}
                      />
                    )}
                  </ToolTipComponent>
                </td>

                <td className={styles.table_data_des}>
                  <span
                    className={`${styles.status} ${
                      styles[item?.status?.toLowerCase()]
                    }`}
                  >
                    {statusMapping[item?.status] || "--"}
                  </span>
                </td>
                <td className={styles.table_data_des}>
                  <span className={styles.table_data_status}>
                    {jira_status || "--"}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ViewDiscreptionTable;
