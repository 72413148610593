import React, { useContext, useMemo } from "react";
import styles from "@/page-components/search-module/search.module.css";
import clsx from "clsx";
import { SearchContext } from "../search-context";
import { useTagsInfoSearch } from "@/services/costExplorer/getTagInfoSearch.repo";
import SearchTableState from "@/components/States/searchTableState";
import {
  API_LOADING_ERROR,
  API_NO_DATA_FOUND_ERROR_CUSTOM_WIDGET,
  API_NOT_FOUND_ERROR,
} from "@/lib/errors/error-constants";
import ScenariosErrorState from "@/components/States/scenariosErrorState";

const TagsResourceTable = ({ service }) => {
  const context = useContext(SearchContext);

  const query = useMemo(() => {
    return {
      service: service,
      accounts: context?.selectAccounts?.length
        ? context.selectAccounts.map((e) => e.id)
        : undefined,
      regions: context?.selectRegions?.length
        ? context.selectRegions.map((e) => e.id)
        : undefined,
      name: context?.name,
      type: context?.type,
      spendingBy: context.filterMapping[context?.filteredData] || "account",
      fromDate: context?.fromDate,
      toDate: context?.toDate,
      serviceName: context?.serviceName,
    };
  }, [
    context.filterMapping,
    context?.filteredData,
    context?.fromDate,
    context?.name,
    context.selectAccounts,
    context.selectRegions,
    context?.serviceName,
    context?.toDate,
    context?.type,
    service,
  ]);

  const { data: tagsData, isLoading, error } = useTagsInfoSearch(query);

  return (
    <>
      <div className={styles.reource_tags}>
        <div className={styles.align_center}>
          <span className={styles.cost_trend}>Tags</span>
          <span className={styles.LDaysDiv}>
            Last {context?.daysDifference} Days
          </span>
        </div>
        {isLoading && <SearchTableState />}
        {error && (
          <ScenariosErrorState
            error={error.message}
            messageConfig={{
              [API_LOADING_ERROR]: {
                message: "Oops!",
                additionalMessage:
                  "We encountered a problem loading your data. Please ensure a stable internet connection and try again. If the issue persists, contact support.",
              },
              [API_NOT_FOUND_ERROR]: {
                message: "404 Error",
                additionalMessage:
                  "We couldn’t find the data you’re looking for. This might be due to a temporary issue or incorrect resource. Please check back later or contact support if the problem persists.",
              },
              [API_NO_DATA_FOUND_ERROR_CUSTOM_WIDGET]: {
                message: "No Data Available",
                additionalMessage:
                  "We couldn’t retrieve data at this moment. This might be due to an absence of data or a temporary issue. Please try again later.",
              },
            }}
          />
        )}
        {tagsData?.tags.length > 0 && (
          <div
            style={{ overflowY: "scroll", maxHeight: "300px", width: "100%" }}
          >
            <table className="table-hover" style={{ width: "100%" }}>
              <thead
                style={{ backgroundColor: "#f6f6f6" }}
                className={styles.stickyHeader}
              >
                <tr>
                  <th className={styles.check_th}>Key</th>
                  <th
                    className={clsx(styles.check_th, styles.tags_text_align)}
                    style={{ width: "50%" }}
                  >
                    Value
                  </th>
                </tr>
              </thead>
              <tbody>
                {(tagsData?.tags || []).map((item, index) => (
                  <tr key={index}>
                    <td className={clsx(styles.check_td)}>{item.tag_key}</td>
                    <td
                      className={clsx(styles.check_td, styles.tags_text_align)}
                    >
                      {item.tag_value}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </>
  );
};

export default TagsResourceTable;
