import React, { useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import Joi from "joi";
import styles from "@/components/state-change/state.module.css";
import jiraAbledIcon from "@/assets/img/jiraAbleIcon.svg";
import { InputFields } from "@/components/state-change/helper";
import AcceptJiraTable from "./accept-jira-table";
import ModalTable from "@/page-components/snapshot/components/modal/modalTable";
import retainAbled from "@/assets/img/retainAbled.svg";
import closeButton from "@/assets/img/closecross.svg";

/** @typedef {import('@/services/snapshots/addAcceptFields.repo').UpdateAcceptRecommendationsReq} UpdateAcceptRecommendationsReq */

/**
 * @typedef IAcceptStatusReq
 * @property {string} comment
 */

const AcceptStatusReq = Joi.object({
  comment: Joi.string().allow("").messages({
    "string.base": "Comment must be a string",
  }),
});

/**
 * @typedef AcceptViaJiraProps
 * @property {boolean} show
 * @property {VoidFunction} handleClose
 * @property {(values: IAcceptStatusReq) => void} onSubmit
 */

/**
 * @param {AcceptViaJiraProps} props
 */
const RetainModal = ({
  show,
  handleClose,
  onSubmit,
  name,
  selectedRows,
  updateStatusPending,
  table_account,
  type
}) => {
  // const [updatedSelectedRows, setUpdatedSelectedRows] = useState(
  //   selectedRows || []
  // );

  const formData = useForm({
    resolver: joiResolver(AcceptStatusReq),
    defaultValues: { comment: "" },
  });

  const handleCancel = () => {
    handleClose();
    formData.reset();
  };

  const handleFormSubmit = (data) => {
    const filteredResources = selectedRows.map((resource) => ({
      resourceId: resource.resource_id || "",
      accountName: resource.account_name || "",
      resourceName: resource.name || resource.idle_resource_name || "",
      resourceCost: resource.saving || 0,
      accountId: resource.account_id || "",
    }));
    const payload = {
      comment: "",
      selectedRows: filteredResources,
    };
    onSubmit(payload);
  };

  return (
    <Offcanvas
      show={show}
      onHide={handleCancel}
      placement="end"
      backdrop="static"
      className={styles.offcanvas_main}
    >
      <Offcanvas.Header className={styles.offcanvas_header_main}>
        <div className={styles.retain_close}>
          <div className={styles.offcanvas_header}>
            <div className={styles.accept_via_jira}>
              <img
                src={retainAbled}
                alt="Accept via Jira"
                style={{ marginRight: "4px" }}
              />
              Retain
            </div>
            <div className={styles.header_desc}>
              <span className={styles.accept_recommend}>
                Retain {name} Recommendation
              </span>
              <span className={styles.accpt_desc}>
                These resources will be stored in Retained, but don’t miss out
                on valuable insights you could act on!
              </span>
            </div>
          </div>
          <img
            src={closeButton}
            alt="Close"
            onClick={handleClose}
            style={{ cursor: "pointer" }}
          ></img>
        </div>
      </Offcanvas.Header>
      <Offcanvas.Body className={styles.offcanvas_body_css}>
        <form
          onSubmit={formData.handleSubmit(handleFormSubmit)}
          className={styles.contentContaineredBox}
        >
          <div className={styles.body_content}>
            <div>
              {" "}
              <div className={styles.table_main}>
                {name === "Snapshots" && <ModalTable name={type} />}
                {name === "Idle Resource" && (
                  <AcceptJiraTable
                    selectedRows={selectedRows}
                    table_account={table_account}
                    // selectedRows={updatedSelectedRows}
                    // onRowsChange={setUpdatedSelectedRows}
                  />
                )}
              </div>
            </div>
          </div>
          <div className={styles.buttonsContainered}>
            {/* <div></div> */}
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                gap: "4px",
              }}
            >
              <button
                id={styles.AcceptId}
                className={styles.cancleButton}
                onClick={handleCancel}
                type="button"
              >
                Cancel
              </button>
              <button
                id={styles.AcceptId}
                className={styles.submitButton}
                // onClick={handleSubmit}
                type="submit"
              >
                {updateStatusPending ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>{" "}
                    Loading...
                  </>
                ) : (
                  "Submit"
                )}
              </button>
            </div>
          </div>
        </form>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default RetainModal;
