import { AppContext } from "@/App.context";
import config from "@/config";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import {
  API_LOADING_ERROR,
  API_NO_DATA_FOUND_FOR_FILTERS_ERROR,
  API_NOT_FOUND_ERROR,
  ERROR_STATUS_CODES,
  API_NO_DATA_FOUND_ERROR,
} from "@/lib/errors/error-constants";

// {
//     "success": true,
//     "message": "Fetched Successfully.",
//     "data": {
//         "message": "",
//         "data": {
//             "activities": [
//                 {
//                     "month": "Jan 25",
//                     "deleted": 4,
//                     "added": 32,
//                     "deleted_savings": 4
//                 }
//             ]
//         }
//     }
// }

/**
 * @typedef GetAllStatistics
 * @property {GetTotalStatistics} data
 */

/**
 * @typedef GetTotalStatistics
 * @property {GetIdleStatistics} data
 */

/**
 * @typedef GetIdleStatistics
 * @property {GetDiskStatistics=} activities
 */

/**
 * @typedef GetIdleStatistics
 * @property {number} added
 * @property {number} deleted
 * @property {number} deleted_savings
 * @property {month}
 */

/**
 * @typedef GetAllStatisticsRes
 * @property {GetAllStatistics[]} statistics
 *  @property {GetIdleFilters} filters
 */
/**
 * @typedef GetStatisticsReq
 * @property {'aws' | 'gcp' | 'azure' | 'all-cloud' } service
 * @property { 'false' | 'true' } untagged
 */

export const getHistoryBarActivity = (token) => {
  /**
   * @param {GetStatisticsReq} req
   * @param {AbortSignal=} signal
   * @returns {Promise<GetAllStatistics>}
   */
  return async ({ service, filters, end_date, start_date, period }, signal) => {
    const body = {
      accounts: filters?.accounts || [],
      regions: filters?.regions || [],
      period: period,
      start_date: start_date,
      end_date: end_date,
    };

    // if (service === "azure") {
    //   body.rg = filters?.rg || [];
    // }
    const url = new URL(
      `${config.apiHost}/v1/${service}/idle-resources/history-activity`
    );

    const owners = await fetch(url.toString(), {
      method: "POST",
      body: JSON.stringify(body),
      signal,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    if (!owners.ok) {
      if (ERROR_STATUS_CODES.includes(owners.status)) {
        throw new Error(API_LOADING_ERROR);
      }
      if (owners.status === 404) {
        throw new Error(API_NOT_FOUND_ERROR);
      }
      throw new Error((await owners.json()).message);
    }

    const data = await owners.json();
    if (data?.data?.data?.activities.length === 0) {
      if (body?.accounts.length || body?.regions.length ) {
        throw new Error(API_NO_DATA_FOUND_FOR_FILTERS_ERROR);
      } else {
        throw new Error(API_NO_DATA_FOUND_ERROR);
      }
    }
    return data;
  };
};

/**
 * @param {GetStatisticsReq} req
 * @param {any[]=} deps
 */
export const useHistoryBarActivity = (req, deps = []) => {
  const appContext = useContext(AppContext);
  const query = useQuery({
    queryKey: ["getHistoryBarActivity", appContext.idToken, req, ...deps],
    queryFn: ({ signal }) =>
      getHistoryBarActivity(appContext.idToken)(req, signal),
  });
  return query;
};
