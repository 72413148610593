/** @type {import('./global')} */
import React, { createContext, useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { differenceInDays, differenceInMonths, format, parse } from "date-fns";
import subDays from "date-fns/subDays";

/**
 * @typedef IdleContextType
 * @property {string[]} selectAccounts
 * @property {React.Dispatch<React.SetStateAction<string[]>>} setSelectAccounts
 * @property {string[]} selectRegions
 * @property {React.Dispatch<React.SetStateAction<string[]>>} setSelectRegions
 * @property {string[]} filterSelectedValue
 * @property {React.Dispatch<React.SetStateAction<string[]>>} setFilterSelectedValue
 * @property {string[]} range
 * @property {React.Dispatch<React.SetStateAction<string[]>>} setRange
 * @property {string[]} name
 * @property {React.Dispatch<React.SetStateAction<string[]>>} setName
 * @property {string[]} type
 * @property {React.Dispatch<React.SetStateAction<string[]>>} setType
 * @property {Object} filterMapping
 * @property {string[]} filteredData
 * @property {React.Dispatch<React.SetStateAction<string[]>>} setFilteredData
 * @property {string[]} servicesSelected
 * @property {React.Dispatch<React.SetStateAction<string[]>>} setServicesSelected
 * @property {string[]} tagsSelected
 * @property {React.Dispatch<React.SetStateAction<string[]>>} setTagsSelected
 * @property {string[]} title
 * @property {React.Dispatch<React.SetStateAction<string[]>>} setTitle
 * @property {string[]} serviceName
 * @property {React.Dispatch<React.SetStateAction<string[]>>} setServiceName
 * @property {string} headerName
 * @property {number} daysDifference
 * @property {number} monthDifference
 */

/**
 * @type {React.Context<IdleContextType>}
 */
export const SearchContext = createContext();

/**
 * @returns {IdleContextType}
 */
export const useSearchContext = () => {
  /** @type {UseState<string[]>} */
  const [selectAccounts, setSelectAccounts] = useState([]);

  /** @type {UseState<string[]>} */
  const [selectRegions, setSelectRegions] = useState([]);

  /** @type {UseState<string[]>} */
  const [range, setRange] = useState([
    {
      startDate: subDays(new Date(), 7),
      endDate: subDays(new Date(), 2),
      key: "selection",
    },
  ]);

  let fromDate = format(range[0].startDate, "yyyy-MM-dd");
  let toDate = format(range[0].endDate, "yyyy-MM-dd");

  const date2 = parse(toDate, "yyyy-MM-dd", new Date());
  const date1 = parse(fromDate, "yyyy-MM-dd", new Date());
  const daysDifference = differenceInDays(date2, date1) + 1;
  const monthDifference = differenceInMonths(date2, date1) + 1;

  const [serviceName, setServiceName] = useState(null);

  /** @type {UseState<string[]>} */
  const [filterSelectedValue, setFilterSelectedValue] = useState("");

  /** @type {UseState<string[]>} */
  const [name, setName] = useState("");

  /** @type {UseState<string[]>} */
  const [type, setType] = useState("");

  const [title, setTitle] = useState("");

  /** @type {UseState<string[]>} */
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const currentName = searchParams.get("name");
    const currentType = searchParams.get("type");
    if (currentName) setName(currentName);
    if (currentType) setType(currentType);
  }, [searchParams]);

  /** @type {UseState<string[]>} */
  const [filteredData, setFilteredData] = useState("account");

  const filterMapping = {
    "Subscription Name": "account",
    "Account Name": "account",
    Tags: "tags",
    Resources: "resources",
    Region: "region",
  };

  /** @type {UseState<string[]>} */
  const [servicesSelected, setServicesSelected] = useState("");

  /**@type {string} */
  let headerName;

  if (type === "serviceCategory") {
    headerName = "service";
  }
  if (type === "service") {
    headerName = "resource";
  }

  /** @type {UseState<string[]>} */
  const [tagsSelected, setTagsSelected] = useState("");

  return {
    selectAccounts,
    setSelectAccounts,
    selectRegions,
    setSelectRegions,
    range,
    setRange,
    fromDate,
    toDate,
    filterSelectedValue,
    setFilterSelectedValue,
    name,
    setName,
    type,
    setType,
    filterMapping,
    filteredData,
    setFilteredData,
    servicesSelected,
    setServicesSelected,
    headerName,
    tagsSelected,
    setTagsSelected,
    daysDifference,
    monthDifference,
    title,
    setTitle,
    serviceName,
    setServiceName,
  };
};

export const SearchProvider = ({ children }) => {
  const context = useSearchContext();
  return (
    <>
      <SearchContext.Provider value={context}>
        {children}
      </SearchContext.Provider>
    </>
  );
};
