import React, { Fragment, useContext, useMemo, useState } from "react";
import { Table, Tooltip, OverlayTrigger } from "react-bootstrap";
import styles from "@/page-components/search-module/search.module.css";
import RemoveIcon from "@/assets/img/filter-delete.svg";
import collapseIcon from "@/assets/img/collapseIcon.svg";
import { SearchContext } from "../search-context";
import clsx from "clsx";
import { useGetTableSearchStatics } from "@/services/costExplorer/getSearchCollapseTable.repo";
import SearchTableState from "@/components/States/searchTableState";
import {
  API_LOADING_ERROR,
  API_NO_DATA_FOUND_ERROR,
  API_NO_DATA_FOUND_FOR_DATE_RANGE_ERROR,
  API_NO_DATA_FOUND_FOR_FILTERS_ERROR,
  API_NOT_FOUND_ERROR,
} from "@/lib/errors/error-constants";
import ScenariosErrorState from "@/components/States/scenariosErrorState";
import CurrencyFormatter from "@/lib/value-converison";

function CollapsibleRow({
  data,
  title,
  account,
  region,
  quantity,
  unit,
  unitPrice,
  cost,
  showRemoveIcon = false,
  defaultIsOpen = false,
  children,
  level = 0,
}) {
  const [isOpen, setIsOpen] = useState(defaultIsOpen);
  const formattedCost =
    cost < 0
      ? `-${new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(Math.abs(cost))}`
      : cost < 0.1
      ? "< $0.1"
      : new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(cost);

  return (
    <Fragment>
      <tr className={styles.collapse_tr}>
        <td
          onClick={() => showRemoveIcon && setIsOpen(!isOpen)}
          style={{
            cursor: showRemoveIcon ? "pointer" : "default",
            paddingLeft: level > 0 ? `${level * 24}px` : "8px",
            fontWeight: level === 0 ? "bold" : "normal",
          }}
        >
          {showRemoveIcon && (
            <img
              src={isOpen ? RemoveIcon : collapseIcon}
              alt=""
              style={{
                marginRight: "8px",
                transform: "translate(0px, -1px)",
              }}
            />
          )}
          {data}
        </td>
        <td>
          {quantity && (
            <ToolTipComponent data={quantity || 0} prefix="">
              {quantity < 0.1 ? (
                "< 0.1"
              ) : (
                <CurrencyFormatter value={quantity} hideDollar={false} />
              )}{" "}
              *{" "}
            </ToolTipComponent>
          )}
          {unitPrice && (
            <ToolTipComponent data={unitPrice || 0}>
              {unitPrice < 0.1 ? (
                "< $ 0.1"
              ) : (
                <CurrencyFormatter value={unitPrice} hideDollar={false} />
              )}
            </ToolTipComponent>
          )}
          {unit ? " / " + unit : ""}
        </td>
        <td className={styles.side_align}>
          {cost !== undefined && cost !== null && (
            <ToolTipComponent data={cost || 0}>
              {formattedCost}
            </ToolTipComponent>
          )}
        </td>
      </tr>

      {isOpen &&
        children?.map((child, index) => (
          <CollapsibleRow
            key={index}
            data={child.title}
            account={child.account}
            region={child.region}
            quantity={child.quantity}
            unit={child.unit}
            unitPrice={child.unitPrice}
            cost={child.cost}
            showRemoveIcon={child.children?.length > 0}
            level={level + 1}
            children={child.children}
          />
        ))}
    </Fragment>
  );
}

export default function ResourceCollapseTable({ service }) {
  const context = useContext(SearchContext);

  const query = useMemo(() => {
    return {
      service: service,
      accounts: context?.selectAccounts?.length
        ? context.selectAccounts.map((e) => e.id)
        : undefined,
      regions: context?.selectRegions?.length
        ? context.selectRegions.map((e) => e.id)
        : undefined,
      name: context?.name,
      type: context?.type,
      spendingBy: context.filterMapping[context?.filteredData] || "account",
      fromDate: context?.fromDate,
      toDate: context?.toDate,
      serviceName: context?.serviceName,
    };
  }, [
    context.filterMapping,
    context?.filteredData,
    context?.fromDate,
    context?.name,
    context.selectAccounts,
    context.selectRegions,
    context?.serviceName,
    context?.toDate,
    context?.type,
    service,
  ]);

  const {
    data: collapseData,
    isLoading,
    error,
  } = useGetTableSearchStatics(query);

  return (
    <>
      {isLoading && <SearchTableState />}
      {error && (
        <ScenariosErrorState
          error={error.message}
          messageConfig={{
            [API_NO_DATA_FOUND_FOR_DATE_RANGE_ERROR]: {
              message: "No Data for the Selected Date Range",
              additionalMessage:
                "The selected date range doesn’t contain any savings data. Try choosing a different range to view available savings.",
            },
            [API_NO_DATA_FOUND_FOR_FILTERS_ERROR]: {
              message: "No Data Available for Selected Filters",
              additionalMessage:
                "It looks like there is no matching your filter criteria. Please try adjusting your filters to explore other options.",
            },
            [API_LOADING_ERROR]: {
              message: "Oops!",
              additionalMessage:
                "We encountered a problem loading your data. Please ensure a stable internet connection and try again. If the issue persists, contact support.",
            },
            [API_NOT_FOUND_ERROR]: {
              message: "404 Error",
              additionalMessage:
                "We couldn’t find the data you’re looking for. This might be due to a temporary issue or incorrect resource. Please check back later or contact support if the problem persists.",
            },
            [API_NO_DATA_FOUND_ERROR]: {
              message: "No Data Available",
              additionalMessage:
                "We couldn’t retrieve data at this moment. This might be due to an absence of data or a temporary issue. Please try again later.",
            },
          }}
        />
      )}
      {collapseData?.usageDetails.length > 0 && (
        <>
          <Table responsive>
            <thead className={styles.collapsibleTableHead}>
              <tr className={styles.tr_head_css}>
                <td className={styles.col_header}>Description</td>
                <td className={styles.col_header}>Usage Quantity</td>
                <td className={clsx(styles.col_header, styles.side_align)}>
                  Amount in USD
                </td>
              </tr>
            </thead>
            <tbody>
              {(collapseData?.usageDetails || []).map((data, index) => (
                <CollapsibleRow
                  key={index}
                  data={data.title}
                  account={data.account}
                  region={data.region}
                  quantity={data.quantity}
                  unit={data.unit}
                  unitPrice={data.unitPrice}
                  cost={data.cost}
                  showRemoveIcon={data.children?.length > 0}
                  level={0}
                  children={data.children}
                />
              ))}
            </tbody>
          </Table>
        </>
      )}
    </>
  );
}

export const ToolTipComponent = ({ data, children, prefix = "$ " }) => {
  const isNegative = data < 0;
  const formattedData =
    (isNegative ? "-" : "") +
    prefix +
    new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 8,
      maximumFractionDigits: 8,
    }).format(Math.abs(data) || 0);

  return (
    <OverlayTrigger
      placement="top"
      overlay={
        <Tooltip id="tooltip" style={{ cursor: "pointer" }}>
          <strong>{formattedData}</strong>
        </Tooltip>
      }
    >
      <span>{children}</span>
    </OverlayTrigger>
  );
};
