import React, { useContext, useEffect, useMemo, useState } from "react";
import style from "./../../ri.module.css";
import { Table } from "react-bootstrap";
import styles from "@/page-components/admin/admin.module.css";
import FilterSection from "../filter_section/Filter";
import ScopeInstanceModal from "./Modals/scope-instance-modal.part";
import { RiContext } from "../../ri.context";
import { useRiStatistics } from "@/services/ri-recommendation/getTableStats.repo";
import TableErrorState from "@/components/States/TableErrorState";
import { useSearchParams } from "react-router-dom";
import Pagination from "@/components/pagination/pagination.component";
import clsx from "clsx";
import download from "@/assets/img/download.svg";
import menuicon from "@/assets/img/menu.svg";
import ScenariosErrorState from "@/components/States/scenariosErrorState";
import {
  API_LOADING_ERROR,
  API_NO_DATA_FOUND_ERROR,
  API_NO_DATA_FOUND_FOR_FILTERS_ERROR,
  API_NOT_FOUND_ERROR,
} from "@/lib/errors/error-constants";
import { useRiStatisticsDownload } from "@/services/ri-recommendation/getTableStatsDownload.repo";
import DropdowmComponent from "@/common/DropdownComponent";
import downlaodVM from "@/assets/img/downloadVM.svg";
import { OverlayTrigger, Tooltip as ReactTooltip } from "react-bootstrap";
import CurrencyFormatter from "@/lib/value-converison";

export default function ScopeInstance({ service, type = "scope" }) {
  const context = useContext(RiContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get("page");
  const itemsPerPage = searchParams.get("itemsPerPage");
  const parsedPage = !Number.isNaN(parseInt(page)) ? parseInt(page) : 1;
  const parsedrecordsPerPage = !Number.isNaN(parseInt(itemsPerPage))
    ? parseInt(itemsPerPage)
    : 10;
  const [hideFilterSection, setHideFilterSection] = useState(false);
  const [width, setwidth] = useState("80");
  const [Filterwidth, setFiltersetwidth] = useState("20");

  const query = useMemo(() => {
    return {
      service,
      filters: {
        reservation:
          context?.selectedReservation &&
          (context?.selectedReservation || []).map((e) => e.id),
        region:
          context?.selectedRegions &&
          (context?.selectedRegions || []).map((e) => e.id),
        vmFamily:
          context?.selectedVMFamily &&
          (context?.selectedVMFamily || []).map((e) => e.id),
        platform:
          context?.SelectedplateformType &&
          (context?.SelectedplateformType || []).map((e) => e.id),
      },
      type,
      page: parsedPage || 1,
      recordsPerPage: parsedrecordsPerPage || 10,
    };
  }, [
    context?.SelectedplateformType,
    context?.selectedRegions,
    context?.selectedReservation,
    context?.selectedVMFamily,
    service,
    type,
    parsedPage,
    parsedrecordsPerPage,
  ]);

  const { data, isLoading, error } = useRiStatistics(query);
  useEffect(() => {
    if (
      error &&
      (error.message === API_NO_DATA_FOUND_ERROR ||
        error.message === API_NOT_FOUND_ERROR ||
        error.message === API_LOADING_ERROR)
    ) {
      setHideFilterSection(true);
    } else {
      setHideFilterSection(false);
    }
  }, [error]);
  useEffect(() => {
    if (hideFilterSection === true) {
      setwidth("100");
      setFiltersetwidth("0");
    }
    if (hideFilterSection === false) {
      setwidth("80");
      setFiltersetwidth("20");
    }
  }, [hideFilterSection]);
  useEffect(() => {
    context?.setTotalCount(data?.data?.count);
  }, [context, data?.data?.count]);

  // ------------for download------------------//

  const downloadQuery = useMemo(() => {
    return {
      service,
      filters: {
        accounts:
          context?.SelectedAccounts &&
          (context?.SelectedAccounts || []).map((e) => e.id),
        region:
          context?.selectedRegions &&
          (context?.selectedRegions || []).map((e) => e.id),
        instance:
          context?.SelecInstanceTypents &&
          (context?.SelecInstanceTypents || []).map((e) => e.id),
        platform:
          context?.SelectedplateformType &&
          (context?.SelectedplateformType || []).map((e) => e.id),
      },
      type,
      page: parsedPage || 1,
      recordsPerPage: parsedrecordsPerPage || 10,
      tableName: "download",
    };
  }, [
    context?.SelecInstanceTypents,
    context?.SelectedAccounts,
    context?.SelectedplateformType,
    context?.selectedRegions,
    parsedPage,
    parsedrecordsPerPage,
    service,
    type,
  ]);
  const { mutate: downloadMutate } = useRiStatisticsDownload(downloadQuery);
  const handleDownloadClick = () => {
    downloadMutate(downloadQuery);
  };

  return (
    <>
      <div style={{ display: "flex" }}>
        <div className={style.mainContainer} style={{ width: `${width}%` }}>
          {isLoading && <TableErrorState />}
          {error && (
            <ScenariosErrorState
              error={error.message}
              messageConfig={{
                [API_NO_DATA_FOUND_FOR_FILTERS_ERROR]: {
                  message: "No Data Available for Selected Filters",
                  additionalMessage:
                    "It looks like there are no data matching your filter criteria. Please try adjusting your filters to explore other options.",
                },
                [API_NO_DATA_FOUND_ERROR]: {
                  message: "No Data Available",
                  additionalMessage:
                    "We couldn’t retrieve data at this moment. This might be due to an absence of data or a temporary issue. Please try again later.",
                },
                [API_LOADING_ERROR]: {
                  message: "Oops!",
                  additionalMessage:
                    "We encountered a problem loading your data. Please ensure a stable internet connection and try again. If the issue persists, contact support.",
                },
                [API_NOT_FOUND_ERROR]: {
                  message: "404 Error",
                  additionalMessage:
                    "We couldn’t find the data you’re looking for. This might be due to a temporary issue or incorrect resource. Please check back later or contact support if the problem persists.",
                },
              }}
            />
          )}
          {data?.data?.reservations.length > 0 && (
            <>
              <div className={style.tableContainer}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span className={style.heading}>
                    Scope & Instance Flexibility Recommendation
                  </span>
                  <div className={style.menu_icon_css1}>
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        <ReactTooltip id="tooltip">
                          Download as CSV
                        </ReactTooltip>
                      }
                    >
                      <span
                        className={styles.downloadButton}
                        onClick={handleDownloadClick}
                      >
                        <img src={downlaodVM} alt="" />
                      </span>
                    </OverlayTrigger>
                  </div>
                </div>

                <Table
                  responsive
                  className={clsx(styles.table, "table-hover")}
                  style={{ overflowX: "scroll", textWrap: "nowrap" }}
                >
                  <thead className={styles.tableHeadStyling}>
                    <tr>
                      {service === "aws" && <th>Account Name/ID</th>}
                      {service === "azure" && <th>Reservation Name</th>}
                      {service === "aws" && <th>RI Type/ID</th>}
                      <th>Region</th>
                      <th>VM Family</th>
                      <th>Term</th>
                      <th style={{ textAlign: "right" }}>Purchased Qty</th>
                      <th>Platform</th>
                      <th>Scope</th>
                      {service === "aws" && <th>Offering Class</th>}
                      {service === "aws" && <th>Offering Type</th>}
                      {service === "azure" && <th>Instance Flexibility</th>}
                      <th style={{ textAlign: "right" }}>Unused Qty</th>
                      <th style={{ textAlign: "right" }}>Unused Cost</th>
                      <th style={{ textAlign: "right" }}>Recommended Qty</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(data?.data?.reservations || []).map((data, index) => (
                      <tr className={styles.tableRowStyling}>
                        <React.Fragment key={index}>
                          <td>
                            {service === "azure" && (
                              <u
                                style={{
                                  color: "#056EE5",
                                  fontWeight: "600",
                                  pointer: "cursor",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  context?.setShowScopeModal(true);
                                  context?.setAccountID(data?.reservationId);
                                }}
                              >
                                {data?.reservationName !== null ?data?.reservationName: "--"}
                              </u>
                            )}
                            {service === "aws" && (
                              <>{data?.reservationName!== null ?data?.reservationName : "--"}</>
                            )}
                          </td>
                          {service === "aws" && (
                            <td>{data?.riType || "--"}</td>
                          )}
                          <td>{data?.region || "--"}</td>
                          <td>{data?.vmFamily || "--"}</td>
                          <td>{data?.term || "--"}</td>
                          <td style={{ textAlign: "right" }}>
                            {data?.purchasedQuantity || 0}
                          </td>
                          <td>{data?.platform || "--"}</td>
                          <td>{data?.scopeName || "--"}</td>
                          {service === "aws" && (
                            <td>{data?.offeringClass || "--"}</td>
                          )}
                          {service === "aws" && (
                            <td>{data?.offeringType || "--"}</td>
                          )}
                          <td style={{ textAlign: "center" }}>
                            {data?.instanceFlexibility || "--"}
                          </td>
                          <td style={{ textAlign: "right" }}>
                            {data?.unusedQuantity || "--"}
                          </td>
                          <td style={{ textAlign: "right" }}>
                          {data?.unusedCost !== null?
                         <CurrencyFormatter value={data?.unusedCost} hideDollar={false} />:"--"}
                            
                          </td>
                          <td style={{ textAlign: "right" }}>
                            {data?.recommendedQuantity || "--"}
                          </td>
                        </React.Fragment>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
              <Pagination totalItems={data?.data?.count || 0} />
            </>
          )}

          {context?.showScopeModal && (
            <ScopeInstanceModal service={service} type={type} />
          )}
        </div>
        {!hideFilterSection && (
          <div
            style={{
              width: `${Filterwidth}%`,
              paddingRight: "40px",
              boxShadow: "-1px 0px 0px 0px rgba(0, 0, 0, 0.04)",
            }}
          >
            <>
              {service === "aws" && (
                <FilterSection
                  service={service}
                  account={"Reservation Name"}
                  type={"VM Family"}
                />
              )}
              {service === "azure" && (
                <FilterSection
                  service={service}
                  account={"Reservation Name"}
                  type={"VM Family"}
                />
              )}
            </>
          </div>
        )}
      </div>
    </>
  );
}
