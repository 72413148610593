import React, { useState, useRef, useMemo, useContext } from "react";
import menuicon from "@/assets/img/menu.svg";
import download from "@/assets/img/download.svg";
import barcharticon from "@/assets/img/graphCharticon.svg";
import linecharticon from "@/assets/img/lineCharticon.svg";
import Dropdown from "react-bootstrap/Dropdown";
import { CSVLink } from "react-csv";
import styles from "@/page-components/idle-resource/idle.module.css";
import StackedBarGraphIdleRes from "../stacked-bar-graph-idle-res.part";
import LineChartIdleResource from "../line-chart-idle-resource.part";
import { useStackedStatistics } from "@/services/IdleResources/getStackedStatistics.repo";
import BarGraphErrorStates from "@/components/States/BarGraphErrorStates";
import ScenariosErrorState from "@/components/States/scenariosErrorState";
import {
  API_LOADING_ERROR,
  API_NOT_FOUND_ERROR,
  API_NO_DATA_FOUND_ERROR,
  API_NO_DATA_FOUND_FOR_FILTERS_ERROR,
} from "@/lib/errors/error-constants";
import IdleHistoryBarChart from "./idle-history-bar-graph.part";
import HistoryLineChart from "./line-chart-history.part";
import { IdleContext } from "../idle-context-file.part";
import { useHistoryBarActivity } from "@/services/IdleResources/getHistoryBarActivity.repo";
import { compareAsc, parse } from "date-fns";

const IdleResourceActivityGraph = ({ service }) => {
  const [chartType, setChartType] = useState("bar");
  const context = useContext(IdleContext);

  const query = useMemo(() => {
    return {
      service,
      period: context?.selectMonthRange.toLowerCase(),
      start_date: context?.fromDate,
      end_date: context?.toDate,
      filters: {
        accounts: context?.selectAccounts?.length
          ? context.selectAccounts.map((e) => e.id)
          : undefined,
        regions: context?.selectRegions?.length
          ? context.selectRegions.map((e) => e.id)
          : undefined,
      },
    };
  }, [
    context?.fromDate,
    context.selectAccounts,
    context?.selectMonthRange,
    context.selectRegions,
    context?.toDate,
    service,
  ]);
  const { data, isLoading, error } = useHistoryBarActivity(query);

  const sortedData = data?.data?.data?.activities.sort((a, b) => {
    const [aMonth, aYear] = a.month.split(" ");
    const [bMonth, bYear] = b.month.split(" ");

    const aDate = new Date(`${aMonth} 1, ${aYear}`);
    const bDate = new Date(`${bMonth} 1, ${bYear}`);

    return compareAsc(aDate, bDate);
  });

  const maxLength = sortedData?.length || 0;
  const csvLinkRef = useRef(null);

  const handleDownloadCsv = () => {
    const headers = [
      "Month",
      ...Object.keys(data?.data?.data?.activities[0]).filter(
        (key) => key !== "formatted_month" || key !== "month"
      ),
    ];

    const csvData = [
      headers,
      ...data?.data?.data?.activities.map((item) => [
        // item.month,
        ...headers.slice(1).map((header) => item[header] || "-"),
      ]),
    ];

    if (csvLinkRef.current) {
      csvLinkRef.current.link.click();
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "start",
          flexDirection: "column",
          gap: "20px",
          padding: "24px 16px 0px 40px",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          {!isLoading && (data || error) && (
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                gap: "8px",
                alignItems: "center",
              }}
            >
              <div className={styles.all_cloud_text}>
                Idle Resources Activity
              </div>
              <div className={styles.last_days_name}>
                Last {maxLength || 0} months
              </div>
            </div>
          )}
          {!error && data?.data?.data?.activities.length > 0 && (
            <div className={styles.menu_icon_css1}>
              <Dropdown style={{ marginBottom: 2 }}>
                <Dropdown.Toggle
                  variant="light"
                  id={styles.custom_dropdown_toggle}
                  className={styles.custom_dropdown_toggle}
                  style={{ background: "transparent", border: "none" }}
                >
                  <img src={menuicon} alt="Menu" />
                </Dropdown.Toggle>
                <Dropdown.Menu className={styles.dropdown_menu}>
                  <Dropdown.Item
                    className={styles.dropdown_item}
                    eventKey="bar"
                    onClick={() => setChartType("bar")}
                  >
                    <img
                      src={barcharticon}
                      alt=""
                      style={{ marginRight: "10px" }}
                    ></img>
                    Bar Chart
                  </Dropdown.Item>
                  <Dropdown.Item
                    className={styles.dropdown_item}
                    eventKey="line"
                    onClick={() => setChartType("line")}
                  >
                    <img
                      src={linecharticon}
                      alt=""
                      style={{ marginRight: "10px" }}
                    ></img>
                    Line Chart
                  </Dropdown.Item>
                  <Dropdown.Divider />

                  <Dropdown.Item
                    className={styles.dropdown_item}
                    eventKey="Download as CSV"
                    onClick={handleDownloadCsv}
                  >
                    <img
                      src={download}
                      alt=""
                      style={{ marginRight: "10px" }}
                    />
                    Download as CSV
                  </Dropdown.Item>
                  <CSVLink
                    data={data?.data?.data?.activities}
                    filename={"HistoryActivities.csv"}
                    target="_blank"
                    ref={csvLinkRef}
                    style={{ display: "none" }}
                  >
                    Download
                  </CSVLink>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          )}
        </div>
        {isLoading && (
          <div style={{ width: "100%" }}>
            <BarGraphErrorStates />
          </div>
        )}
        {!isLoading && error && (
          <ScenariosErrorState
            error={error.message}
            messageConfig={{
              [API_NO_DATA_FOUND_FOR_FILTERS_ERROR]: {
                message: "No Data Available for Selected Filters",
                additionalMessage:
                  "It looks like there is no matching your filter criteria. Please try adjusting your filters to explore other options.",
              },
              [API_LOADING_ERROR]: {
                message: "Oops!",
                additionalMessage:
                  "We encountered a problem loading your data. Please ensure a stable internet connection and try again. If the issue persists, contact support.",
              },
              [API_NOT_FOUND_ERROR]: {
                message: "404 Error",
                additionalMessage:
                  "We couldn’t find the data you’re looking for. This might be due to a temporary issue or incorrect resource. Please check back later or contact support if the problem persists.",
              },
              [API_NO_DATA_FOUND_ERROR]: {
                message: "No Idle Resource Data Available",
                additionalMessage:
                  "We couldn’t retrieve idle resource data at this moment. This might be due to an absence of data or a temporary issue. Please try again later.",
              },
            }}
          />
        )}
        {data && data?.data?.data?.activities.length > 0 && (
          <div style={{ width: "100%" }}>
            {data && chartType === "bar" ? (
              <IdleHistoryBarChart data={sortedData} />
            ) : (
              <HistoryLineChart lineChartData={sortedData} />
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default IdleResourceActivityGraph;
