/** @type {import('./global')} */
import React, { createContext, useState } from "react";

/**
 * @type {React.Context<BudgetType>}
 */
/**
 * @typedef Budget_ContextType
 * @property {string[]} SelectedAccounts
 * @property {React.Dispatch<React.SetStateAction<string>>} setSelectedAccounts
 * @property {string[]} SelectedTags
 * @property {React.Dispatch<React.SetStateAction<string>>} setSelectedtags
 * @property {string[]} SelectedEmail
 * @property {React.Dispatch<React.SetStateAction<string>>} setSelectedEmail
 * @property {string[]} selectedGroup
 * @property {React.Dispatch<React.SetStateAction<string>>} setSelectedGroup
 * @property {string[]} tempSelectTagValues
 * @property {React.Dispatch<React.SetStateAction<string>>} setTempSelectTagValues
 */

export const BudgetContext = createContext();

/**
 * @returns {Budget_ContextType}
 */
export const useBudgetContext = () => {
  /** @type {UseState<string>} */
  const [SelectedAccounts, setSelectedAccounts] = useState("");
  /** @type {UseState<string>} */
  const [SelectedResource, setSelectedResource] = useState("");
  /** @type {UseState<string>} */
  const [SelectedManagement, setSelectedManagement] = useState("");

  /** @type {UseState<string[]>} */
  const [SelectedEmail, setSelectedEmail] = useState([]);

  /** @type {UseState<string>} */
  const [orderBy, setOrderBy] = useState("cost");

  const [SelectedTags, setSelectedtags] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState("management_group");
  const [selectedGroupDetail, setSelectedGroupDetail] =
    useState("management_group");
  const [selectedGroupForm, setSelectedGroupForm] =
    useState("management_group");
  const [selectedGroupAws, setSelectedGroupAws] = useState("organization");
  const [SelectedAccountValue, setSelectedAccountValue] = useState([]);
  const [SelectedRgValue, setSelectedRgValue] = useState([]);
  const [SelectedManagementGroup, setSelectedManagementGroup] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);
  const [selectedTagValues, setSelectedTagValues] = useState([]);
  const [extractData, setExtractData] = useState([]);
  const [extractTagsData, setExtractTagsData] = useState([]);
  const [DetailShow, setDetailShow] = useState("");
  const [NewlyView, setNewlyView] = useState("auto");
  const [modalType, setmodalType] = useState("");
  const [BudgetView, setbudgetView] = useState("card");
  const [BudgetViewNewly, setbudgetViewNewly] = useState("card");
  const [BudgetViewSnap, setbudgetViewSnap] = useState("card");
  const [BudgetViewUnused, setbudgetViewUnused] = useState("card");
  const [tempSelectTagValues, setTempSelectTagValues] = useState([]);

  return {
    BudgetView,
    setbudgetView,
    BudgetViewNewly,
    setbudgetViewNewly,
    BudgetViewSnap,
    setbudgetViewSnap,
    BudgetViewUnused,
    setbudgetViewUnused,
    orderBy,
    setOrderBy,
    modalType,
    setmodalType,
    NewlyView,
    setNewlyView,
    DetailShow,
    setDetailShow,
    selectedGroupAws,
    setSelectedGroupAws,
    selectedGroupDetail,
    setSelectedGroupDetail,
    selectedGroupForm,
    setSelectedGroupForm,
    selectedValues,
    setExtractTagsData,
    extractTagsData,
    setSelectedValues,
    selectedTagValues,
    setSelectedTagValues,
    extractData,
    setExtractData,
    SelectedAccountValue,
    setSelectedAccountValue,
    SelectedRgValue,
    setSelectedRgValue,
    SelectedManagementGroup,
    setSelectedManagementGroup,
    SelectedResource,
    setSelectedResource,
    SelectedManagement,
    setSelectedManagement,
    selectedGroup,
    setSelectedGroup,
    SelectedAccounts,
    setSelectedAccounts,
    SelectedTags,
    setSelectedtags,
    SelectedEmail,
    setSelectedEmail,
    tempSelectTagValues,
    setTempSelectTagValues,
  };
};

export const Budget_Provider = ({ children }) => {
  const context = useBudgetContext();

  return (
    <>
      <BudgetContext.Provider value={context}>
        {children}
      </BudgetContext.Provider>
    </>
  );
};
